import { Box } from "@chakra-ui/react";
import { FC, useContext } from "react";
import { MediaProps } from ".";
import { PersistedMediaContext } from "../../../components/PersistedMedia";
import { PlayerContext } from "../../../libs/playerContext";
import { parseMediaMetaData } from "../metaData";

export const VideoMedia: FC<MediaProps> = ({
  widgetMedia,
  offlineMedia,
  nextMedia,
}) => {
  const { onMediaError } = useContext(PersistedMediaContext);
  const { isMuted } = useContext(PlayerContext);
  const mediaMetaData = parseMediaMetaData(widgetMedia.data);
  return (
    <Box
      key={"video-container-" + widgetMedia.id}
      position="relative"
      width="100%"
      height="100%"
      overflow="hidden"
    >
      <video
        key={"video" + widgetMedia.id}
        autoPlay
        src={offlineMedia.offlineUrl || ""}
        onError={(e) => {
          onMediaError(offlineMedia);
        }}
        muted={isMuted}
        onEnded={(e) => {
          console.log("video ended...");
          nextMedia();
          e.currentTarget.currentTime = 0;
          e.currentTarget.play();
        }}
        style={{
          position: "absolute",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
          overflow: "hidden",
          objectFit: (mediaMetaData.object_fit || "fill") as
            | "fill"
            | "contain"
            | "cover",
        }}
      ></video>
    </Box>
  );
};
