import React, { createContext } from "react";
import { MediaContentFragment } from "../generated/graphql";
import {
  PersitedMedia,
  ProvidedPersistedMedia,
  usePersistedMedias,
} from "../libs/persistMedia";

type PersistedMediaProps = {
  mediaList: MediaContentFragment[];
};

export const PersistedMediaContext = createContext<ProvidedPersistedMedia>({
  persistedMedia: {},
  setPersistedMedia: () => {},
  onMediaError: (offlineMedia: PersitedMedia) => null,
});

export const PersistedMedia: React.FC<PersistedMediaProps> = ({
  children,
  mediaList,
}) => {
  const provided = usePersistedMedias(mediaList);

  return (
    <PersistedMediaContext.Provider value={provided}>
      {children}
    </PersistedMediaContext.Provider>
  );
};
