import { Box, Center, HStack, Text, VStack } from "@chakra-ui/layout";
import { PinInput, PinInputField } from "@chakra-ui/pin-input";
import { useToast } from "@chakra-ui/toast";

import useWebSocket from "react-use-websocket";
import { useRegisterDisplayMutation } from "../generated/graphql";
import {
  handlePlatformMessage,
  PlatformAPI,
  PlatformLogging,
  useHeartBeat,
} from "../libs/platformAPI";
import { storeDisplayToken, useIsRegistered } from "../libs/auth";

export const Register = () => {
  const toast = useToast();
  const { sendJsonMessage, readyState } = useWebSocket("ws://localhost:8765", {
    onMessage: handlePlatformMessage,
  });
  const { registered } = useIsRegistered();
  PlatformAPI.init(sendJsonMessage, readyState);
  useHeartBeat(sendJsonMessage, readyState);
  const [registerDisplay] = useRegisterDisplayMutation({
    onError: (error) =>
      toast({
        position: "top",
        title: "error",
        description: error.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      }),
    fetchPolicy: "no-cache",
  });
  if (registered) {
    window.location.href = "/";
  }
  return (
    <Center height="100vh" width="100vw">
      <Box
        bg="gray.700"
        border="1px solid"
        borderColor="gray.600"
        height="xs"
        width="lg"
        borderRadius="24px"
      >
        <Center height="100%">
          <VStack spacing="24px">
            <Text fontSize="xl" fontWeight="bold">
              Enregistrer un nouvel écran
            </Text>
            <HStack>
              <PinInput
                type="alphanumeric"
                size="lg"
                onComplete={async (value) => {
                  const result = await registerDisplay({
                    variables: { pinCode: value },
                  });
                  if (result.errors && result.errors.length > 0) {
                    toast({
                      position: "top",
                      title: "error",
                      description: result.errors[0].message,
                      status: "error",
                      duration: 9000,
                      isClosable: true,
                    });
                  } else {
                    if (result.data?.registerDisplay?.token) {
                      sendJsonMessage({
                        type: "INFO",
                        data: {
                          type: "DEVICE_PARAMS",
                          data: {
                            displayToken: result.data.registerDisplay.token,
                          },
                        },
                      });
                      await storeDisplayToken(
                        result.data.registerDisplay.token
                      );
                      PlatformLogging.getLogger().info({
                        message: "Display registered",
                        displayToken: result.data.registerDisplay.token,
                      });
                      window.location.href = "/";
                    }
                  }
                }}
              >
                <PinInputField />
                <PinInputField />
                <PinInputField />
                <PinInputField />
                <PinInputField />
                <PinInputField />
              </PinInput>
            </HStack>
          </VStack>
        </Center>
      </Box>
    </Center>
  );
};
