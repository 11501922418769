import { WidgetType } from "@govisupro/studio";
import { PlaylistContextProvider } from "./contextDevice";
import { MediaPlayer } from "./PlayerDevice";

export const PlaylistWidget: WidgetType = {
  id: "5f0f8f8f-f9b8-4f7b-b8b8-f9b8f9b8f9b8",
  name: "Playlist",
  icon: <></>,
  type: "playlist",
  inHeadbar: true,
  description: "A playlist of medias",
  config: null,
  createdAt: "2022-05-24T16:09:00.000Z",
  updatedAt: "2022-05-24T16:09:00.000Z",
  isActive: true,
  isDeleted: false,
  scope: ["media"],
  controlPanel: () => <></>,
  playerPreview: () => <></>,
  playerDevice: MediaPlayer,
  context: () => <></>,
  load: null,
  factory: () => ({
    id: Date.now().toString(),
    data: {},
  }),
  contextDevice: PlaylistContextProvider,
  scalePlayer: false,
};
