import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * The `Date` scalar type represents a Date
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  Date: any;
  /**
   * The `DateTime` scalar type represents a DateTime
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  DateTime: any;
  /** The `Decimal` scalar type represents a python Decimal. */
  Decimal: any;
  /**
   * The `GenericScalar` scalar type represents a generic
   * GraphQL scalar value that could be:
   * String, Boolean, Int, Float, List or Object.
   */
  GenericScalar: any;
  /**
   * Allows use of a JSON String for input / output from the GraphQL schema.
   *
   * Use of this type is *not recommended* as you lose the benefits of having a defined, static
   * schema (one of the key benefits of GraphQL).
   */
  JSONString: any;
  /**
   * The `Time` scalar type represents a Time value as
   * specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  Time: any;
};

export type ActivateWidgetOnCustomerInput = {
  customerIds: Array<Maybe<Scalars['ID']>>;
  widgetId: Scalars['ID'];
  check: Scalars['Boolean'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ActivateWidgetOnCustomerPayload = {
  __typename?: 'ActivateWidgetOnCustomerPayload';
  customers?: Maybe<CustomerConnection>;
  clientMutationId?: Maybe<Scalars['String']>;
};


export type ActivateWidgetOnCustomerPayloadCustomersArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type AddDisplayGroupToAllocationInput = {
  id: Scalars['ID'];
  displayGroupId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type AddDisplayGroupToAllocationPayload = {
  __typename?: 'AddDisplayGroupToAllocationPayload';
  publicationAllocation?: Maybe<PublicationAllocationNode>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type AddDisplayToAllocationInput = {
  id: Scalars['ID'];
  displayId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type AddDisplayToAllocationPayload = {
  __typename?: 'AddDisplayToAllocationPayload';
  publicationAllocation?: Maybe<PublicationAllocationNode>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type AddGroupToComputerInput = {
  computerId: Scalars['ID'];
  groupId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type AddGroupToComputerPayload = {
  __typename?: 'AddGroupToComputerPayload';
  displayGroup?: Maybe<DisplayComputerNode>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type AddUserInput = {
  userId: Scalars['ID'];
  customerId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type AddUserPayload = {
  __typename?: 'AddUserPayload';
  user?: Maybe<UserNode>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type AssignBackOfficeVersionToCustomerInput = {
  customerId: Scalars['ID'];
  backofficeVersionId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type AssignBackOfficeVersionToCustomerPayload = {
  __typename?: 'AssignBackOfficeVersionToCustomerPayload';
  customer?: Maybe<CustomerNode>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type AssignPlayerVersionToComputerInput = {
  computerId: Scalars['ID'];
  playerVersionId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type AssignPlayerVersionToComputerPayload = {
  __typename?: 'AssignPlayerVersionToComputerPayload';
  computer?: Maybe<DisplayComputerNode>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type BackOfficeVersionNode = Node & {
  __typename?: 'BackOfficeVersionNode';
  /** The ID of the object */
  id: Scalars['ID'];
  version: Scalars['String'];
  number: Scalars['Float'];
  date: Scalars['Date'];
  dockerImageUrl?: Maybe<Scalars['String']>;
  dockerImageTag?: Maybe<Scalars['String']>;
  releaseNote?: Maybe<Scalars['String']>;
  customerSet: CustomerNodeConnection;
};


export type BackOfficeVersionNodeCustomerSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  name_Icontains?: Maybe<Scalars['String']>;
};

export type BackOfficeVersionNodeConnection = {
  __typename?: 'BackOfficeVersionNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<BackOfficeVersionNodeEdge>>;
  totalCount?: Maybe<Scalars['Int']>;
  edgeCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `BackOfficeVersionNode` and its cursor. */
export type BackOfficeVersionNodeEdge = {
  __typename?: 'BackOfficeVersionNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<BackOfficeVersionNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type ConvertVideoMutationInput = {
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ConvertVideoMutationPayload = {
  __typename?: 'ConvertVideoMutationPayload';
  ok?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateBackOfficeVersionInput = {
  version: Scalars['String'];
  number?: Maybe<Scalars['Float']>;
  dockerImageUrl?: Maybe<Scalars['String']>;
  dockerImageTag?: Maybe<Scalars['String']>;
  releaseNote?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateBackOfficeVersionPayload = {
  __typename?: 'CreateBackOfficeVersionPayload';
  backofficeVersion?: Maybe<BackOfficeVersionNode>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateCustomerInput = {
  name: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateCustomerPayload = {
  __typename?: 'CreateCustomerPayload';
  customer?: Maybe<CustomerNode>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateDisplayComputerInput = {
  customerId: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  isMuted?: Maybe<Scalars['Boolean']>;
  ipAddress?: Maybe<Scalars['String']>;
  browserId?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Decimal']>;
  longitude?: Maybe<Scalars['Decimal']>;
  monday?: Maybe<Scalars['Boolean']>;
  tuesday?: Maybe<Scalars['Boolean']>;
  wednesday?: Maybe<Scalars['Boolean']>;
  thursday?: Maybe<Scalars['Boolean']>;
  friday?: Maybe<Scalars['Boolean']>;
  saturday?: Maybe<Scalars['Boolean']>;
  sunday?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateDisplayComputerPayload = {
  __typename?: 'CreateDisplayComputerPayload';
  displayComputer?: Maybe<DisplayComputerNode>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateDisplayGroupInput = {
  customerId: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isContainer?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateDisplayGroupPayload = {
  __typename?: 'CreateDisplayGroupPayload';
  displayGroup?: Maybe<DisplayGroupNode>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateDisplayInput = {
  computerId: Scalars['ID'];
  offsetX?: Maybe<Scalars['Int']>;
  offsetY?: Maybe<Scalars['Int']>;
  widthPx: Scalars['Int'];
  heightPx: Scalars['Int'];
  pitch?: Maybe<Scalars['Float']>;
  orientation?: Maybe<Scalars['Int']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateDisplayPayload = {
  __typename?: 'CreateDisplayPayload';
  display?: Maybe<DisplayNode>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateLayoutItemInput = {
  screenId: Scalars['ID'];
  widgetName: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  x?: Maybe<Scalars['String']>;
  y?: Maybe<Scalars['String']>;
  z?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['String']>;
  widgetData?: Maybe<Scalars['JSONString']>;
  animations?: Maybe<Scalars['JSONString']>;
  scale?: Maybe<Scalars['Float']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateLayoutItemPayload = {
  __typename?: 'CreateLayoutItemPayload';
  layoutItem?: Maybe<LayoutItemNode>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateMediaContentInput = {
  customerId: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  contentType?: Maybe<MediaTypeEnum>;
  webpageUrl?: Maybe<Scalars['String']>;
  fallbackRefreshPeriod?: Maybe<Scalars['Int']>;
  sha256?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateMediaContentPayload = {
  __typename?: 'CreateMediaContentPayload';
  media?: Maybe<MediaContentNode>;
  presignedUrl?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreatePlayerVersionInput = {
  version: Scalars['String'];
  number: Scalars['Float'];
  date?: Maybe<Scalars['String']>;
  dockerImageUrl?: Maybe<Scalars['String']>;
  dockerImageTag?: Maybe<Scalars['String']>;
  releaseNote?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreatePlayerVersionPayload = {
  __typename?: 'CreatePlayerVersionPayload';
  playerVersion?: Maybe<PlayerVersionNode>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreatePublicationAllocationInput = {
  publicationId: Scalars['ID'];
  priority?: Maybe<PriorityEnum>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  startTime?: Maybe<Scalars['Time']>;
  endTime?: Maybe<Scalars['Time']>;
  active?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreatePublicationAllocationPayload = {
  __typename?: 'CreatePublicationAllocationPayload';
  publicationAllocation?: Maybe<PublicationAllocationNode>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreatePublicationInput = {
  customerId: Scalars['ID'];
  name: Scalars['String'];
  active?: Maybe<Scalars['Boolean']>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreatePublicationMediaInput = {
  publicationScreenId: Scalars['ID'];
  layoutItemId: Scalars['ID'];
  mediaId?: Maybe<Scalars['ID']>;
  objectFit?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreatePublicationMediaPayload = {
  __typename?: 'CreatePublicationMediaPayload';
  publicationMedia?: Maybe<PublicationMediaNode>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreatePublicationPayload = {
  __typename?: 'CreatePublicationPayload';
  publication?: Maybe<PublicationNode>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreatePublicationScreenInput = {
  publicationId: Scalars['ID'];
  layout?: Maybe<Scalars['ID']>;
  duration?: Maybe<Scalars['Int']>;
  backgroundColor?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreatePublicationScreenPayload = {
  __typename?: 'CreatePublicationScreenPayload';
  publicationScreen?: Maybe<PublicationScreenNode>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateRegistrationPinInput = {
  displayId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateRegistrationPinPayload = {
  __typename?: 'CreateRegistrationPinPayload';
  pinCode?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateWidgetMediaInput = {
  widgetLayoutItemId: Scalars['ID'];
  mediaContentId: Scalars['ID'];
  data?: Maybe<Scalars['JSONString']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateWidgetMediaPayload = {
  __typename?: 'CreateWidgetMediaPayload';
  widgetMedia?: Maybe<WidgetMediaNode>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CustomerConnection = {
  __typename?: 'CustomerConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CustomerEdge>>;
  totalCount?: Maybe<Scalars['Int']>;
  edgeCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `Customer` and its cursor. */
export type CustomerEdge = {
  __typename?: 'CustomerEdge';
  /** The item at the end of the edge */
  node?: Maybe<CustomerNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type CustomerNode = Node & {
  __typename?: 'CustomerNode';
  /** The ID of the object */
  id: Scalars['ID'];
  name: Scalars['String'];
  address: Scalars['String'];
  backofficeVersion?: Maybe<BackOfficeVersionNode>;
  userSet: UserNodeConnection;
  mediacontentSet: MediaContentNodeConnection;
  displaygroupSet: DisplayGroupNodeConnection;
  displaycomputerSet: DisplayComputerNodeConnection;
  layoutSet: LayoutNodeConnection;
  publicationSet: PublicationNodeConnection;
  activatedWidgets: WidgetNodeConnection;
};


export type CustomerNodeUserSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  email_Icontains?: Maybe<Scalars['String']>;
};


export type CustomerNodeMediacontentSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  contentType?: Maybe<Scalars['String']>;
  name_Icontains?: Maybe<Scalars['String']>;
};


export type CustomerNodeDisplaygroupSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  name_Icontains?: Maybe<Scalars['String']>;
  name_Istartswith?: Maybe<Scalars['String']>;
  isContainer?: Maybe<Scalars['Boolean']>;
};


export type CustomerNodeDisplaycomputerSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  name_Icontains?: Maybe<Scalars['String']>;
  name_Istartswith?: Maybe<Scalars['String']>;
  ipAddress?: Maybe<Scalars['String']>;
  ipAddress_Istartswith?: Maybe<Scalars['String']>;
  latitude_Lte?: Maybe<Scalars['Float']>;
  latitude_Gte?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude_Lte?: Maybe<Scalars['Float']>;
  longitude_Gte?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};


export type CustomerNodeLayoutSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  name_Icontains?: Maybe<Scalars['String']>;
  name_Istartswith?: Maybe<Scalars['String']>;
};


export type CustomerNodePublicationSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  name_Icontains?: Maybe<Scalars['String']>;
  name_Istartswith?: Maybe<Scalars['String']>;
};


export type CustomerNodeActivatedWidgetsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  name_Icontains?: Maybe<Scalars['String']>;
  name_Istartswith?: Maybe<Scalars['String']>;
  description_Icontains?: Maybe<Scalars['String']>;
  description_Istartswith?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
};

export type CustomerNodeConnection = {
  __typename?: 'CustomerNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<CustomerNodeEdge>>;
  totalCount?: Maybe<Scalars['Int']>;
  edgeCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `CustomerNode` and its cursor. */
export type CustomerNodeEdge = {
  __typename?: 'CustomerNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<CustomerNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};




export type DeleteBackOfficeVersionInput = {
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteBackOfficeVersionPayload = {
  __typename?: 'DeleteBackOfficeVersionPayload';
  ok?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteDisplayComputerInput = {
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteDisplayComputerPayload = {
  __typename?: 'DeleteDisplayComputerPayload';
  ok?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteDisplayGroupInput = {
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteDisplayGroupPayload = {
  __typename?: 'DeleteDisplayGroupPayload';
  ok?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteDisplayInput = {
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteDisplayPayload = {
  __typename?: 'DeleteDisplayPayload';
  ok?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteJsonWebTokenCookie = {
  __typename?: 'DeleteJSONWebTokenCookie';
  deleted: Scalars['Boolean'];
};

export type DeleteLayoutItemInput = {
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteLayoutItemPayload = {
  __typename?: 'DeleteLayoutItemPayload';
  ok?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeletePlayerVersionInput = {
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeletePlayerVersionPayload = {
  __typename?: 'DeletePlayerVersionPayload';
  ok?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeletePublicationAllocationInput = {
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeletePublicationAllocationPayload = {
  __typename?: 'DeletePublicationAllocationPayload';
  ok?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeletePublicationInput = {
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeletePublicationMediaInput = {
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeletePublicationMediaPayload = {
  __typename?: 'DeletePublicationMediaPayload';
  ok?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeletePublicationPayload = {
  __typename?: 'DeletePublicationPayload';
  ok?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeletePublicationScreenInput = {
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeletePublicationScreenPayload = {
  __typename?: 'DeletePublicationScreenPayload';
  ok?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteRegistrationInput = {
  displayId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteRegistrationPayload = {
  __typename?: 'DeleteRegistrationPayload';
  ok?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteWidgetMediaInput = {
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteWidgetMediaPayload = {
  __typename?: 'DeleteWidgetMediaPayload';
  ok?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DisplayComputerAdminFieldsNode = Node & {
  __typename?: 'DisplayComputerAdminFieldsNode';
  /** The ID of the object */
  id: Scalars['ID'];
  computer: DisplayComputerNode;
  anydesk: Scalars['String'];
  informations: Scalars['String'];
  serialNumber: Scalars['String'];
  designation: Scalars['String'];
};

export type DisplayComputerNode = Node & {
  __typename?: 'DisplayComputerNode';
  /** The ID of the object */
  id: Scalars['ID'];
  customer: CustomerNode;
  groups: DisplayGroupNodeConnection;
  name: Scalars['String'];
  startTime: Scalars['Time'];
  endTime: Scalars['Time'];
  description?: Maybe<Scalars['String']>;
  ipAddress: Scalars['String'];
  browserId: Scalars['String'];
  isMuted: Scalars['Boolean'];
  latitude?: Maybe<Scalars['Decimal']>;
  longitude?: Maybe<Scalars['Decimal']>;
  isActive: Scalars['Boolean'];
  hasLog: Scalars['Boolean'];
  monday: Scalars['Boolean'];
  tuesday: Scalars['Boolean'];
  wednesday: Scalars['Boolean'];
  thursday: Scalars['Boolean'];
  friday: Scalars['Boolean'];
  saturday: Scalars['Boolean'];
  sunday: Scalars['Boolean'];
  playerVersion?: Maybe<PlayerVersionNode>;
  platformVersion?: Maybe<Scalars['Float']>;
  platformVersionFk?: Maybe<PlatformVersionNode>;
  displaycomputeradminfields?: Maybe<DisplayComputerAdminFieldsNode>;
  displaySet: DisplayNodeConnection;
  lastConnection?: Maybe<Scalars['DateTime']>;
  status?: Maybe<DisplayStatusType>;
};


export type DisplayComputerNodeGroupsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  name_Icontains?: Maybe<Scalars['String']>;
  name_Istartswith?: Maybe<Scalars['String']>;
  isContainer?: Maybe<Scalars['Boolean']>;
};


export type DisplayComputerNodeDisplaySetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  widthPx_Lte?: Maybe<Scalars['Int']>;
  widthPx_Gte?: Maybe<Scalars['Int']>;
  widthPx?: Maybe<Scalars['Int']>;
  heightPx_Lte?: Maybe<Scalars['Int']>;
  heightPx_Gte?: Maybe<Scalars['Int']>;
  heightPx?: Maybe<Scalars['Int']>;
  pitch_Lte?: Maybe<Scalars['Float']>;
  pitch_Gte?: Maybe<Scalars['Float']>;
  pitch?: Maybe<Scalars['Float']>;
  orientation?: Maybe<Scalars['String']>;
};

export type DisplayComputerNodeConnection = {
  __typename?: 'DisplayComputerNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<DisplayComputerNodeEdge>>;
  totalCount?: Maybe<Scalars['Int']>;
  edgeCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `DisplayComputerNode` and its cursor. */
export type DisplayComputerNodeEdge = {
  __typename?: 'DisplayComputerNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<DisplayComputerNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type DisplayCredentialNode = Node & {
  __typename?: 'DisplayCredentialNode';
  uuid: Scalars['String'];
  activated: Scalars['Boolean'];
  /** The ID of the object */
  id: Scalars['ID'];
};

export type DisplayGroupNode = Node & {
  __typename?: 'DisplayGroupNode';
  /** The ID of the object */
  id: Scalars['ID'];
  customer: CustomerNode;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  isContainer: Scalars['Boolean'];
  displaycomputerSet: DisplayComputerNodeConnection;
  publicationallocationSet: PublicationAllocationNodeConnection;
};


export type DisplayGroupNodeDisplaycomputerSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  name_Icontains?: Maybe<Scalars['String']>;
  name_Istartswith?: Maybe<Scalars['String']>;
  ipAddress?: Maybe<Scalars['String']>;
  ipAddress_Istartswith?: Maybe<Scalars['String']>;
  latitude_Lte?: Maybe<Scalars['Float']>;
  latitude_Gte?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude_Lte?: Maybe<Scalars['Float']>;
  longitude_Gte?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};


export type DisplayGroupNodePublicationallocationSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['DateTime']>;
  startDate_Gte?: Maybe<Scalars['DateTime']>;
  startDate_Lte?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  endDate_Gte?: Maybe<Scalars['DateTime']>;
  endDate_Lte?: Maybe<Scalars['DateTime']>;
  startTime?: Maybe<Scalars['Time']>;
  startTime_Gte?: Maybe<Scalars['Time']>;
  startTime_Lte?: Maybe<Scalars['Time']>;
  endTime?: Maybe<Scalars['Time']>;
  endTime_Gte?: Maybe<Scalars['Time']>;
  endTime_Lte?: Maybe<Scalars['Time']>;
  active?: Maybe<Scalars['Boolean']>;
  active_Gte?: Maybe<Scalars['Boolean']>;
  active_Lte?: Maybe<Scalars['Boolean']>;
};

export type DisplayGroupNodeConnection = {
  __typename?: 'DisplayGroupNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<DisplayGroupNodeEdge>>;
  totalCount?: Maybe<Scalars['Int']>;
  edgeCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `DisplayGroupNode` and its cursor. */
export type DisplayGroupNodeEdge = {
  __typename?: 'DisplayGroupNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<DisplayGroupNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type DisplayMediaErrorEventNode = Node & {
  __typename?: 'DisplayMediaErrorEventNode';
  /** The ID of the object */
  id: Scalars['ID'];
  timestamp: Scalars['DateTime'];
  media: MediaContentNode;
  display: DisplayNode;
};

export type DisplayMediaErrorEventNodeConnection = {
  __typename?: 'DisplayMediaErrorEventNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<DisplayMediaErrorEventNodeEdge>>;
  totalCount?: Maybe<Scalars['Int']>;
  edgeCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `DisplayMediaErrorEventNode` and its cursor. */
export type DisplayMediaErrorEventNodeEdge = {
  __typename?: 'DisplayMediaErrorEventNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<DisplayMediaErrorEventNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type DisplayMediaStatusNode = Node & {
  __typename?: 'DisplayMediaStatusNode';
  /** The ID of the object */
  id: Scalars['ID'];
  timestamp: Scalars['DateTime'];
  media: MediaContentNode;
  display: DisplayNode;
  status: DisplayParkDisplayMediaStatusStatusChoices;
};

export type DisplayMediaStatusNodeConnection = {
  __typename?: 'DisplayMediaStatusNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<DisplayMediaStatusNodeEdge>>;
  totalCount?: Maybe<Scalars['Int']>;
  edgeCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `DisplayMediaStatusNode` and its cursor. */
export type DisplayMediaStatusNodeEdge = {
  __typename?: 'DisplayMediaStatusNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<DisplayMediaStatusNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type DisplayNode = Node & {
  __typename?: 'DisplayNode';
  /** The ID of the object */
  id: Scalars['ID'];
  computer: DisplayComputerNode;
  offsetX: Scalars['Int'];
  offsetY: Scalars['Int'];
  widthPx: Scalars['Int'];
  heightPx: Scalars['Int'];
  pitch?: Maybe<Scalars['Float']>;
  orientation: DisplayParkDisplayOrientationChoices;
  displaycredential?: Maybe<DisplayCredentialNode>;
  displaymediastatusSet: DisplayMediaStatusNodeConnection;
  displaymediaerroreventSet: DisplayMediaErrorEventNodeConnection;
  publicationallocationSet: PublicationAllocationNodeConnection;
};


export type DisplayNodeDisplaymediastatusSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type DisplayNodeDisplaymediaerroreventSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type DisplayNodePublicationallocationSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['DateTime']>;
  startDate_Gte?: Maybe<Scalars['DateTime']>;
  startDate_Lte?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  endDate_Gte?: Maybe<Scalars['DateTime']>;
  endDate_Lte?: Maybe<Scalars['DateTime']>;
  startTime?: Maybe<Scalars['Time']>;
  startTime_Gte?: Maybe<Scalars['Time']>;
  startTime_Lte?: Maybe<Scalars['Time']>;
  endTime?: Maybe<Scalars['Time']>;
  endTime_Gte?: Maybe<Scalars['Time']>;
  endTime_Lte?: Maybe<Scalars['Time']>;
  active?: Maybe<Scalars['Boolean']>;
  active_Gte?: Maybe<Scalars['Boolean']>;
  active_Lte?: Maybe<Scalars['Boolean']>;
};

export type DisplayNodeConnection = {
  __typename?: 'DisplayNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<DisplayNodeEdge>>;
  totalCount?: Maybe<Scalars['Int']>;
  edgeCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `DisplayNode` and its cursor. */
export type DisplayNodeEdge = {
  __typename?: 'DisplayNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<DisplayNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** An enumeration. */
export enum DisplayParkDisplayMediaStatusStatusChoices {
  /** WAITING */
  Wa = 'WA',
  /** DOWNLOADING */
  Do = 'DO',
  /** RETRYING */
  Re = 'RE',
  /** READY */
  Ra = 'RA',
  /** MISMATCH */
  Mi = 'MI'
}

/** An enumeration. */
export enum DisplayParkDisplayOrientationChoices {
  /** UP */
  Up = 'UP',
  /** LEFT */
  Lf = 'LF',
  /** RIGHT */
  Rg = 'RG',
  /** DOWN */
  Dw = 'DW'
}

/** An enumeration. */
export enum DisplayStatusType {
  Disabled = 'DISABLED',
  Standby = 'STANDBY',
  Empty = 'EMPTY',
  Connected = 'CONNECTED',
  Disconnected = 'DISCONNECTED',
  Live = 'LIVE',
  Error = 'ERROR'
}

export type DuplicatePublicationScreenInput = {
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DuplicatePublicationScreenPayload = {
  __typename?: 'DuplicatePublicationScreenPayload';
  publicationScreen?: Maybe<PublicationScreenNode>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ForgottenPasswordInput = {
  email?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ForgottenPasswordPayload = {
  __typename?: 'ForgottenPasswordPayload';
  success?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};



export type LayoutItemNode = Node & {
  __typename?: 'LayoutItemNode';
  /** The ID of the object */
  id: Scalars['ID'];
  layout?: Maybe<LayoutNode>;
  screen?: Maybe<PublicationScreenNode>;
  name: Scalars['String'];
  x: Scalars['String'];
  y: Scalars['String'];
  z: Scalars['String'];
  width: Scalars['String'];
  height: Scalars['String'];
  alpha: Scalars['Float'];
  animations?: Maybe<Scalars['JSONString']>;
  scale?: Maybe<Scalars['Float']>;
  publicationmediaSet: PublicationMediaNodeConnection;
  widget?: Maybe<WidgetLayoutItemNode>;
};


export type LayoutItemNodePublicationmediaSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  order_Lte?: Maybe<Scalars['Int']>;
  order_Gte?: Maybe<Scalars['Int']>;
};

export type LayoutItemNodeConnection = {
  __typename?: 'LayoutItemNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<LayoutItemNodeEdge>>;
  totalCount?: Maybe<Scalars['Int']>;
  edgeCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `LayoutItemNode` and its cursor. */
export type LayoutItemNodeEdge = {
  __typename?: 'LayoutItemNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<LayoutItemNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type LayoutNode = Node & {
  __typename?: 'LayoutNode';
  /** The ID of the object */
  id: Scalars['ID'];
  name: Scalars['String'];
  customer?: Maybe<CustomerNode>;
  layoutitemSet: LayoutItemNodeConnection;
  publicationscreenSet: PublicationScreenNodeConnection;
};


export type LayoutNodeLayoutitemSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  x?: Maybe<Scalars['String']>;
  x_Lte?: Maybe<Scalars['String']>;
  x_Gte?: Maybe<Scalars['String']>;
  y?: Maybe<Scalars['String']>;
  y_Lte?: Maybe<Scalars['String']>;
  y_Gte?: Maybe<Scalars['String']>;
  z?: Maybe<Scalars['String']>;
  z_Lte?: Maybe<Scalars['String']>;
  z_Gte?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['String']>;
  width_Lte?: Maybe<Scalars['String']>;
  width_Gte?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['String']>;
  height_Lte?: Maybe<Scalars['String']>;
  height_Gte?: Maybe<Scalars['String']>;
  alpha?: Maybe<Scalars['Float']>;
  alpha_Lte?: Maybe<Scalars['Float']>;
  alpha_Gte?: Maybe<Scalars['Float']>;
};


export type LayoutNodePublicationscreenSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  duration?: Maybe<Scalars['Int']>;
  duration_Lte?: Maybe<Scalars['Int']>;
  duration_Gte?: Maybe<Scalars['Int']>;
};

export type LayoutNodeConnection = {
  __typename?: 'LayoutNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<LayoutNodeEdge>>;
  totalCount?: Maybe<Scalars['Int']>;
  edgeCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `LayoutNode` and its cursor. */
export type LayoutNodeEdge = {
  __typename?: 'LayoutNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<LayoutNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type LogDisplayMediaErrorInput = {
  displayId: Scalars['ID'];
  mediaId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type LogDisplayMediaErrorPayload = {
  __typename?: 'LogDisplayMediaErrorPayload';
  displayMediaError?: Maybe<DisplayMediaErrorEventNode>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type MediaContentNode = Node & {
  __typename?: 'MediaContentNode';
  /** The ID of the object */
  id: Scalars['ID'];
  customer?: Maybe<CustomerNode>;
  name: Scalars['String'];
  contentType: MediaMediaContentContentTypeChoices;
  webpageUrl?: Maybe<Scalars['String']>;
  fallbackUrl?: Maybe<Scalars['String']>;
  fallbackRefreshPeriod?: Maybe<Scalars['Int']>;
  contentConverted: Scalars['Boolean'];
  sha256?: Maybe<Scalars['String']>;
  displaymediastatusSet: DisplayMediaStatusNodeConnection;
  displaymediaerroreventSet: DisplayMediaErrorEventNodeConnection;
  publicationmediaSet: PublicationMediaNodeConnection;
  widgetmediaSet: WidgetMediaNodeConnection;
  signedUrl?: Maybe<Scalars['String']>;
  thumbnailUrl?: Maybe<Scalars['String']>;
};


export type MediaContentNodeDisplaymediastatusSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type MediaContentNodeDisplaymediaerroreventSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type MediaContentNodePublicationmediaSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  order_Lte?: Maybe<Scalars['Int']>;
  order_Gte?: Maybe<Scalars['Int']>;
};


export type MediaContentNodeWidgetmediaSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  widgetLayoutItem?: Maybe<Scalars['ID']>;
};

export type MediaContentNodeConnection = {
  __typename?: 'MediaContentNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<MediaContentNodeEdge>>;
  totalCount?: Maybe<Scalars['Int']>;
  edgeCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `MediaContentNode` and its cursor. */
export type MediaContentNodeEdge = {
  __typename?: 'MediaContentNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<MediaContentNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** An enumeration. */
export enum MediaMediaContentContentTypeChoices {
  /** IMAGE */
  Im = 'IM',
  /** VIDEO */
  Vd = 'VD',
  /** IFRAME */
  If = 'IF'
}

/** An enumeration. */
export enum MediaTypeEnum {
  Image = 'IMAGE',
  Video = 'VIDEO',
  Iframe = 'IFRAME'
}

export type Mutation = {
  __typename?: 'Mutation';
  createPlayerVersion?: Maybe<CreatePlayerVersionPayload>;
  updatePlayerVersion?: Maybe<UpdatePlayerVersionPayload>;
  deletePlayerVersion?: Maybe<DeletePlayerVersionPayload>;
  assignPlayerVersionToComputer?: Maybe<AssignPlayerVersionToComputerPayload>;
  createBackofficeVersion?: Maybe<CreateBackOfficeVersionPayload>;
  updateBackofficeVersion?: Maybe<UpdateBackOfficeVersionPayload>;
  deleteBackofficeVersion?: Maybe<DeleteBackOfficeVersionPayload>;
  assignBackofficeVersionToCustomer?: Maybe<AssignBackOfficeVersionToCustomerPayload>;
  activateWidgetOnCustomer?: Maybe<ActivateWidgetOnCustomerPayload>;
  createWidgetMedia?: Maybe<CreateWidgetMediaPayload>;
  updateWidgetMedia?: Maybe<UpdateWidgetMediaPayload>;
  deleteWidgetMedia?: Maybe<DeleteWidgetMediaPayload>;
  createPublicationMedia?: Maybe<CreatePublicationMediaPayload>;
  updatePublicationMedia?: Maybe<UpdatePublicationMediaPayload>;
  deletePublicationMedia?: Maybe<DeletePublicationMediaPayload>;
  createPublicationAllocation?: Maybe<CreatePublicationAllocationPayload>;
  updatePublicationAllocation?: Maybe<UpdatePublicationAllocationPayload>;
  deletePublicationAllocation?: Maybe<DeletePublicationAllocationPayload>;
  addDisplayGroupToAllocation?: Maybe<AddDisplayGroupToAllocationPayload>;
  addDisplayToAllocation?: Maybe<AddDisplayToAllocationPayload>;
  removeDisplayGroupToAllocation?: Maybe<RemoveDisplayGroupToAllocationPayload>;
  removeDisplayToAllocation?: Maybe<RemoveDisplayToAllocationPayload>;
  createPublicationScreen?: Maybe<CreatePublicationScreenPayload>;
  updatePublicationScreen?: Maybe<UpdatePublicationScreenPayload>;
  deletePublicationScreen?: Maybe<DeletePublicationScreenPayload>;
  duplicatePublicationScreen?: Maybe<DuplicatePublicationScreenPayload>;
  createPublication?: Maybe<CreatePublicationPayload>;
  updatePublication?: Maybe<UpdatePublicationPayload>;
  deletePublication?: Maybe<DeletePublicationPayload>;
  createLayoutItem?: Maybe<CreateLayoutItemPayload>;
  updateLayoutItem?: Maybe<UpdateLayoutItemPayload>;
  deleteLayoutItem?: Maybe<DeleteLayoutItemPayload>;
  createMediaContent?: Maybe<CreateMediaContentPayload>;
  convertMediaContent?: Maybe<ConvertVideoMutationPayload>;
  updateAdminFields?: Maybe<UpdateAdminFieldsPayload>;
  setDisplayMediaStatus?: Maybe<SetDisplayMediaStatusPayload>;
  logDisplayMediaError?: Maybe<LogDisplayMediaErrorPayload>;
  createDisplayGroup?: Maybe<CreateDisplayGroupPayload>;
  updateDisplayGroup?: Maybe<UpdateDisplayGroupPayload>;
  deleteDisplayGroup?: Maybe<DeleteDisplayGroupPayload>;
  createDisplay?: Maybe<CreateDisplayPayload>;
  updateDisplay?: Maybe<UpdateDisplayPayload>;
  deleteDisplay?: Maybe<DeleteDisplayPayload>;
  createDisplayComputer?: Maybe<CreateDisplayComputerPayload>;
  updateDisplayComputer?: Maybe<UpdateDisplayComputerPayload>;
  deleteDisplayComputer?: Maybe<DeleteDisplayComputerPayload>;
  addGroupToComputer?: Maybe<AddGroupToComputerPayload>;
  removeGroupToComputer?: Maybe<RemoveGroupToComputerPayload>;
  createRegistrationPin?: Maybe<CreateRegistrationPinPayload>;
  deleteRegistration?: Maybe<DeleteRegistrationPayload>;
  registerDisplay?: Maybe<RegisterDisplayPayload>;
  createCustomer?: Maybe<CreateCustomerPayload>;
  addUser?: Maybe<AddUserPayload>;
  removeUser?: Maybe<RemoveUserPayload>;
  registerUser?: Maybe<RegisterUserPayload>;
  updateUser?: Maybe<UpdateUserPayload>;
  forgottenPassword?: Maybe<ForgottenPasswordPayload>;
  resetPassword?: Maybe<ResetPasswordPayload>;
  /** Obtain JSON Web Token mutation */
  tokenAuth?: Maybe<ObtainJsonWebToken>;
  verifyToken?: Maybe<Verify>;
  refreshToken?: Maybe<Refresh>;
  deleteTokenCookie?: Maybe<DeleteJsonWebTokenCookie>;
};


export type MutationCreatePlayerVersionArgs = {
  input: CreatePlayerVersionInput;
};


export type MutationUpdatePlayerVersionArgs = {
  input: UpdatePlayerVersionInput;
};


export type MutationDeletePlayerVersionArgs = {
  input: DeletePlayerVersionInput;
};


export type MutationAssignPlayerVersionToComputerArgs = {
  input: AssignPlayerVersionToComputerInput;
};


export type MutationCreateBackofficeVersionArgs = {
  input: CreateBackOfficeVersionInput;
};


export type MutationUpdateBackofficeVersionArgs = {
  input: UpdateBackOfficeVersionInput;
};


export type MutationDeleteBackofficeVersionArgs = {
  input: DeleteBackOfficeVersionInput;
};


export type MutationAssignBackofficeVersionToCustomerArgs = {
  input: AssignBackOfficeVersionToCustomerInput;
};


export type MutationActivateWidgetOnCustomerArgs = {
  input: ActivateWidgetOnCustomerInput;
};


export type MutationCreateWidgetMediaArgs = {
  input: CreateWidgetMediaInput;
};


export type MutationUpdateWidgetMediaArgs = {
  input: UpdateWidgetMediaInput;
};


export type MutationDeleteWidgetMediaArgs = {
  input: DeleteWidgetMediaInput;
};


export type MutationCreatePublicationMediaArgs = {
  input: CreatePublicationMediaInput;
};


export type MutationUpdatePublicationMediaArgs = {
  input: UpdatePublicationMediaInput;
};


export type MutationDeletePublicationMediaArgs = {
  input: DeletePublicationMediaInput;
};


export type MutationCreatePublicationAllocationArgs = {
  input: CreatePublicationAllocationInput;
};


export type MutationUpdatePublicationAllocationArgs = {
  input: UpdatePublicationAllocationInput;
};


export type MutationDeletePublicationAllocationArgs = {
  input: DeletePublicationAllocationInput;
};


export type MutationAddDisplayGroupToAllocationArgs = {
  input: AddDisplayGroupToAllocationInput;
};


export type MutationAddDisplayToAllocationArgs = {
  input: AddDisplayToAllocationInput;
};


export type MutationRemoveDisplayGroupToAllocationArgs = {
  input: RemoveDisplayGroupToAllocationInput;
};


export type MutationRemoveDisplayToAllocationArgs = {
  input: RemoveDisplayToAllocationInput;
};


export type MutationCreatePublicationScreenArgs = {
  input: CreatePublicationScreenInput;
};


export type MutationUpdatePublicationScreenArgs = {
  input: UpdatePublicationScreenInput;
};


export type MutationDeletePublicationScreenArgs = {
  input: DeletePublicationScreenInput;
};


export type MutationDuplicatePublicationScreenArgs = {
  input: DuplicatePublicationScreenInput;
};


export type MutationCreatePublicationArgs = {
  input: CreatePublicationInput;
};


export type MutationUpdatePublicationArgs = {
  input: UpdatePublicationInput;
};


export type MutationDeletePublicationArgs = {
  input: DeletePublicationInput;
};


export type MutationCreateLayoutItemArgs = {
  input: CreateLayoutItemInput;
};


export type MutationUpdateLayoutItemArgs = {
  input: UpdateLayoutItemInput;
};


export type MutationDeleteLayoutItemArgs = {
  input: DeleteLayoutItemInput;
};


export type MutationCreateMediaContentArgs = {
  input: CreateMediaContentInput;
};


export type MutationConvertMediaContentArgs = {
  input: ConvertVideoMutationInput;
};


export type MutationUpdateAdminFieldsArgs = {
  input: UpdateAdminFieldsInput;
};


export type MutationSetDisplayMediaStatusArgs = {
  input: SetDisplayMediaStatusInput;
};


export type MutationLogDisplayMediaErrorArgs = {
  input: LogDisplayMediaErrorInput;
};


export type MutationCreateDisplayGroupArgs = {
  input: CreateDisplayGroupInput;
};


export type MutationUpdateDisplayGroupArgs = {
  input: UpdateDisplayGroupInput;
};


export type MutationDeleteDisplayGroupArgs = {
  input: DeleteDisplayGroupInput;
};


export type MutationCreateDisplayArgs = {
  input: CreateDisplayInput;
};


export type MutationUpdateDisplayArgs = {
  input: UpdateDisplayInput;
};


export type MutationDeleteDisplayArgs = {
  input: DeleteDisplayInput;
};


export type MutationCreateDisplayComputerArgs = {
  input: CreateDisplayComputerInput;
};


export type MutationUpdateDisplayComputerArgs = {
  input: UpdateDisplayComputerInput;
};


export type MutationDeleteDisplayComputerArgs = {
  input: DeleteDisplayComputerInput;
};


export type MutationAddGroupToComputerArgs = {
  input: AddGroupToComputerInput;
};


export type MutationRemoveGroupToComputerArgs = {
  input: RemoveGroupToComputerInput;
};


export type MutationCreateRegistrationPinArgs = {
  input: CreateRegistrationPinInput;
};


export type MutationDeleteRegistrationArgs = {
  input: DeleteRegistrationInput;
};


export type MutationRegisterDisplayArgs = {
  input: RegisterDisplayInput;
};


export type MutationCreateCustomerArgs = {
  input: CreateCustomerInput;
};


export type MutationAddUserArgs = {
  input: AddUserInput;
};


export type MutationRemoveUserArgs = {
  input: RemoveUserInput;
};


export type MutationRegisterUserArgs = {
  input: RegisterUserInput;
};


export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};


export type MutationForgottenPasswordArgs = {
  input: ForgottenPasswordInput;
};


export type MutationResetPasswordArgs = {
  input: ResetPasswordInput;
};


export type MutationTokenAuthArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationVerifyTokenArgs = {
  token?: Maybe<Scalars['String']>;
};


export type MutationRefreshTokenArgs = {
  token?: Maybe<Scalars['String']>;
};

/** An object with an ID */
export type Node = {
  /** The ID of the object */
  id: Scalars['ID'];
};

/** Obtain JSON Web Token mutation */
export type ObtainJsonWebToken = {
  __typename?: 'ObtainJSONWebToken';
  payload: Scalars['GenericScalar'];
  refreshExpiresIn: Scalars['Int'];
  token: Scalars['String'];
};

/** The Relay compliant `PageInfo` type, containing data necessary to paginate this connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
};

export type PlatformVersionNode = Node & {
  __typename?: 'PlatformVersionNode';
  /** The ID of the object */
  id: Scalars['ID'];
  version: Scalars['String'];
  number: Scalars['Float'];
  date: Scalars['Date'];
  windowsInstaller?: Maybe<Scalars['String']>;
  linuxInstaller?: Maybe<Scalars['String']>;
  raspberryInstaller?: Maybe<Scalars['String']>;
  releaseNote?: Maybe<Scalars['String']>;
  displaycomputerSet: DisplayComputerNodeConnection;
};


export type PlatformVersionNodeDisplaycomputerSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  name_Icontains?: Maybe<Scalars['String']>;
  name_Istartswith?: Maybe<Scalars['String']>;
  ipAddress?: Maybe<Scalars['String']>;
  ipAddress_Istartswith?: Maybe<Scalars['String']>;
  latitude_Lte?: Maybe<Scalars['Float']>;
  latitude_Gte?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude_Lte?: Maybe<Scalars['Float']>;
  longitude_Gte?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

export type PlatformVersionNodeConnection = {
  __typename?: 'PlatformVersionNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PlatformVersionNodeEdge>>;
  totalCount?: Maybe<Scalars['Int']>;
  edgeCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `PlatformVersionNode` and its cursor. */
export type PlatformVersionNodeEdge = {
  __typename?: 'PlatformVersionNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<PlatformVersionNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type PlayerVersionNode = Node & {
  __typename?: 'PlayerVersionNode';
  /** The ID of the object */
  id: Scalars['ID'];
  version: Scalars['String'];
  number: Scalars['Float'];
  date: Scalars['Date'];
  dockerImageUrl?: Maybe<Scalars['String']>;
  dockerImageTag?: Maybe<Scalars['String']>;
  releaseNote?: Maybe<Scalars['String']>;
  minimumBackofficeVersion: Scalars['Float'];
  displaycomputerSet: DisplayComputerNodeConnection;
};


export type PlayerVersionNodeDisplaycomputerSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  name_Icontains?: Maybe<Scalars['String']>;
  name_Istartswith?: Maybe<Scalars['String']>;
  ipAddress?: Maybe<Scalars['String']>;
  ipAddress_Istartswith?: Maybe<Scalars['String']>;
  latitude_Lte?: Maybe<Scalars['Float']>;
  latitude_Gte?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude_Lte?: Maybe<Scalars['Float']>;
  longitude_Gte?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};

export type PlayerVersionNodeConnection = {
  __typename?: 'PlayerVersionNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PlayerVersionNodeEdge>>;
  totalCount?: Maybe<Scalars['Int']>;
  edgeCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `PlayerVersionNode` and its cursor. */
export type PlayerVersionNodeEdge = {
  __typename?: 'PlayerVersionNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<PlayerVersionNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** An enumeration. */
export enum PriorityEnum {
  VeryHigh = 'VERY_HIGH',
  High = 'HIGH',
  Medium = 'MEDIUM',
  Low = 'LOW',
  VeryLow = 'VERY_LOW'
}

export type PublicationAllocationNode = Node & {
  __typename?: 'PublicationAllocationNode';
  /** The ID of the object */
  id: Scalars['ID'];
  publication: PublicationNode;
  priority: PublicationsPublicationAllocationPriorityChoices;
  active: Scalars['Boolean'];
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  startTime?: Maybe<Scalars['Time']>;
  endTime?: Maybe<Scalars['Time']>;
  display: DisplayNodeConnection;
  displayGroup: DisplayGroupNodeConnection;
};


export type PublicationAllocationNodeDisplayArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  widthPx_Lte?: Maybe<Scalars['Int']>;
  widthPx_Gte?: Maybe<Scalars['Int']>;
  widthPx?: Maybe<Scalars['Int']>;
  heightPx_Lte?: Maybe<Scalars['Int']>;
  heightPx_Gte?: Maybe<Scalars['Int']>;
  heightPx?: Maybe<Scalars['Int']>;
  pitch_Lte?: Maybe<Scalars['Float']>;
  pitch_Gte?: Maybe<Scalars['Float']>;
  pitch?: Maybe<Scalars['Float']>;
  orientation?: Maybe<Scalars['String']>;
};


export type PublicationAllocationNodeDisplayGroupArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  name_Icontains?: Maybe<Scalars['String']>;
  name_Istartswith?: Maybe<Scalars['String']>;
  isContainer?: Maybe<Scalars['Boolean']>;
};

export type PublicationAllocationNodeConnection = {
  __typename?: 'PublicationAllocationNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PublicationAllocationNodeEdge>>;
  totalCount?: Maybe<Scalars['Int']>;
  edgeCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `PublicationAllocationNode` and its cursor. */
export type PublicationAllocationNodeEdge = {
  __typename?: 'PublicationAllocationNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<PublicationAllocationNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type PublicationMediaNode = Node & {
  __typename?: 'PublicationMediaNode';
  /** The ID of the object */
  id: Scalars['ID'];
  publicationScreen: PublicationScreenNode;
  order: Scalars['Int'];
  layoutItem?: Maybe<LayoutItemNode>;
  duration: Scalars['Int'];
  objectFit: Scalars['String'];
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  media?: Maybe<MediaContentNode>;
};

export type PublicationMediaNodeConnection = {
  __typename?: 'PublicationMediaNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PublicationMediaNodeEdge>>;
  totalCount?: Maybe<Scalars['Int']>;
  edgeCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `PublicationMediaNode` and its cursor. */
export type PublicationMediaNodeEdge = {
  __typename?: 'PublicationMediaNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<PublicationMediaNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type PublicationNode = Node & {
  __typename?: 'PublicationNode';
  /** The ID of the object */
  id: Scalars['ID'];
  customer: CustomerNode;
  name: Scalars['String'];
  active: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  width: Scalars['Int'];
  height: Scalars['Int'];
  publicationallocationSet: PublicationAllocationNodeConnection;
  publicationscreenSet: PublicationScreenNodeConnection;
};


export type PublicationNodePublicationallocationSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['DateTime']>;
  startDate_Gte?: Maybe<Scalars['DateTime']>;
  startDate_Lte?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  endDate_Gte?: Maybe<Scalars['DateTime']>;
  endDate_Lte?: Maybe<Scalars['DateTime']>;
  startTime?: Maybe<Scalars['Time']>;
  startTime_Gte?: Maybe<Scalars['Time']>;
  startTime_Lte?: Maybe<Scalars['Time']>;
  endTime?: Maybe<Scalars['Time']>;
  endTime_Gte?: Maybe<Scalars['Time']>;
  endTime_Lte?: Maybe<Scalars['Time']>;
  active?: Maybe<Scalars['Boolean']>;
  active_Gte?: Maybe<Scalars['Boolean']>;
  active_Lte?: Maybe<Scalars['Boolean']>;
};


export type PublicationNodePublicationscreenSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  duration?: Maybe<Scalars['Int']>;
  duration_Lte?: Maybe<Scalars['Int']>;
  duration_Gte?: Maybe<Scalars['Int']>;
};

export type PublicationNodeConnection = {
  __typename?: 'PublicationNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PublicationNodeEdge>>;
  totalCount?: Maybe<Scalars['Int']>;
  edgeCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `PublicationNode` and its cursor. */
export type PublicationNodeEdge = {
  __typename?: 'PublicationNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<PublicationNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type PublicationScreenNode = Node & {
  __typename?: 'PublicationScreenNode';
  /** The ID of the object */
  id: Scalars['ID'];
  publication: PublicationNode;
  layout?: Maybe<LayoutNode>;
  duration?: Maybe<Scalars['Int']>;
  backgroundColor: Scalars['String'];
  layoutitemSet: LayoutItemNodeConnection;
  publicationmediaSet: PublicationMediaNodeConnection;
  thumbnailUrl?: Maybe<Scalars['String']>;
  putThumbnailUrl?: Maybe<Scalars['String']>;
};


export type PublicationScreenNodeLayoutitemSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  x?: Maybe<Scalars['String']>;
  x_Lte?: Maybe<Scalars['String']>;
  x_Gte?: Maybe<Scalars['String']>;
  y?: Maybe<Scalars['String']>;
  y_Lte?: Maybe<Scalars['String']>;
  y_Gte?: Maybe<Scalars['String']>;
  z?: Maybe<Scalars['String']>;
  z_Lte?: Maybe<Scalars['String']>;
  z_Gte?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['String']>;
  width_Lte?: Maybe<Scalars['String']>;
  width_Gte?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['String']>;
  height_Lte?: Maybe<Scalars['String']>;
  height_Gte?: Maybe<Scalars['String']>;
  alpha?: Maybe<Scalars['Float']>;
  alpha_Lte?: Maybe<Scalars['Float']>;
  alpha_Gte?: Maybe<Scalars['Float']>;
};


export type PublicationScreenNodePublicationmediaSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  order_Lte?: Maybe<Scalars['Int']>;
  order_Gte?: Maybe<Scalars['Int']>;
};

export type PublicationScreenNodeConnection = {
  __typename?: 'PublicationScreenNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<PublicationScreenNodeEdge>>;
  totalCount?: Maybe<Scalars['Int']>;
  edgeCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `PublicationScreenNode` and its cursor. */
export type PublicationScreenNodeEdge = {
  __typename?: 'PublicationScreenNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<PublicationScreenNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

/** An enumeration. */
export enum PublicationsPublicationAllocationPriorityChoices {
  /** VERY_HIGH */
  A_4 = 'A_4',
  /** HIGH */
  A_3 = 'A_3',
  /** MEDIUM */
  A_2 = 'A_2',
  /** LOW */
  A_1 = 'A_1',
  /** VERY_LOW */
  A_0 = 'A_0'
}

export type Query = {
  __typename?: 'Query';
  backofficeVersion?: Maybe<BackOfficeVersionNode>;
  playerVersion?: Maybe<PlayerVersionNode>;
  platformVersion?: Maybe<PlatformVersionNode>;
  backofficeVersionList?: Maybe<BackOfficeVersionNodeConnection>;
  playerVersionList?: Maybe<PlayerVersionNodeConnection>;
  platformVersionList?: Maybe<PlatformVersionNodeConnection>;
  requestLoki?: Maybe<Scalars['String']>;
  widget?: Maybe<WidgetNode>;
  allWidgets?: Maybe<WidgetNodeConnection>;
  mediaContent?: Maybe<MediaContentNode>;
  mediaContents?: Maybe<MediaContentNodeConnection>;
  layout?: Maybe<LayoutNode>;
  layouts?: Maybe<LayoutNodeConnection>;
  layoutItem?: Maybe<LayoutItemNode>;
  layoutItems?: Maybe<LayoutItemNodeConnection>;
  display?: Maybe<DisplayNode>;
  displayProgram?: Maybe<DisplayNode>;
  displays?: Maybe<DisplayNodeConnection>;
  displayComputer?: Maybe<DisplayComputerNode>;
  displayComputers?: Maybe<DisplayComputerNodeConnection>;
  displayGroup?: Maybe<DisplayGroupNode>;
  displayGroups?: Maybe<DisplayGroupNodeConnection>;
  me?: Maybe<UserNode>;
  user?: Maybe<UserNode>;
  users?: Maybe<UserNodeConnection>;
  customer?: Maybe<CustomerNode>;
  customers?: Maybe<CustomerNodeConnection>;
  publicationAllocation?: Maybe<PublicationAllocationNode>;
  publicationAllocations?: Maybe<PublicationAllocationNodeConnection>;
  publication?: Maybe<PublicationNode>;
  publications?: Maybe<PublicationNodeConnection>;
  publicationScreen?: Maybe<PublicationScreenNode>;
  publicationScreens?: Maybe<PublicationScreenNodeConnection>;
  publicationMedia?: Maybe<PublicationMediaNode>;
  publicationMedias?: Maybe<PublicationMediaNodeConnection>;
};


export type QueryBackofficeVersionArgs = {
  id: Scalars['ID'];
};


export type QueryPlayerVersionArgs = {
  id: Scalars['ID'];
};


export type QueryPlatformVersionArgs = {
  id: Scalars['ID'];
};


export type QueryBackofficeVersionListArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  number?: Maybe<Scalars['Float']>;
  number_Lte?: Maybe<Scalars['Float']>;
  number_Gte?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['String']>;
  version_Icontains?: Maybe<Scalars['String']>;
};


export type QueryPlayerVersionListArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  number?: Maybe<Scalars['Float']>;
  number_Lte?: Maybe<Scalars['Float']>;
  number_Gte?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['String']>;
  version_Icontains?: Maybe<Scalars['String']>;
};


export type QueryPlatformVersionListArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  number?: Maybe<Scalars['Float']>;
  number_Lte?: Maybe<Scalars['Float']>;
  number_Gte?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['String']>;
  version_Icontains?: Maybe<Scalars['String']>;
};


export type QueryRequestLokiArgs = {
  query: Scalars['String'];
  start?: Maybe<Scalars['DateTime']>;
  end?: Maybe<Scalars['DateTime']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryWidgetArgs = {
  id: Scalars['ID'];
};


export type QueryAllWidgetsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  name_Icontains?: Maybe<Scalars['String']>;
  name_Istartswith?: Maybe<Scalars['String']>;
  description_Icontains?: Maybe<Scalars['String']>;
  description_Istartswith?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
};


export type QueryMediaContentArgs = {
  id: Scalars['ID'];
};


export type QueryMediaContentsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  contentType?: Maybe<Scalars['String']>;
  name_Icontains?: Maybe<Scalars['String']>;
};


export type QueryLayoutArgs = {
  id: Scalars['ID'];
};


export type QueryLayoutsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  name_Icontains?: Maybe<Scalars['String']>;
  name_Istartswith?: Maybe<Scalars['String']>;
};


export type QueryLayoutItemArgs = {
  id: Scalars['ID'];
};


export type QueryLayoutItemsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  x?: Maybe<Scalars['String']>;
  x_Lte?: Maybe<Scalars['String']>;
  x_Gte?: Maybe<Scalars['String']>;
  y?: Maybe<Scalars['String']>;
  y_Lte?: Maybe<Scalars['String']>;
  y_Gte?: Maybe<Scalars['String']>;
  z?: Maybe<Scalars['String']>;
  z_Lte?: Maybe<Scalars['String']>;
  z_Gte?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['String']>;
  width_Lte?: Maybe<Scalars['String']>;
  width_Gte?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['String']>;
  height_Lte?: Maybe<Scalars['String']>;
  height_Gte?: Maybe<Scalars['String']>;
  alpha?: Maybe<Scalars['Float']>;
  alpha_Lte?: Maybe<Scalars['Float']>;
  alpha_Gte?: Maybe<Scalars['Float']>;
};


export type QueryDisplayArgs = {
  id: Scalars['ID'];
};


export type QueryDisplaysArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  widthPx_Lte?: Maybe<Scalars['Int']>;
  widthPx_Gte?: Maybe<Scalars['Int']>;
  widthPx?: Maybe<Scalars['Int']>;
  heightPx_Lte?: Maybe<Scalars['Int']>;
  heightPx_Gte?: Maybe<Scalars['Int']>;
  heightPx?: Maybe<Scalars['Int']>;
  pitch_Lte?: Maybe<Scalars['Float']>;
  pitch_Gte?: Maybe<Scalars['Float']>;
  pitch?: Maybe<Scalars['Float']>;
  orientation?: Maybe<Scalars['String']>;
};


export type QueryDisplayComputerArgs = {
  id: Scalars['ID'];
};


export type QueryDisplayComputersArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  name_Icontains?: Maybe<Scalars['String']>;
  name_Istartswith?: Maybe<Scalars['String']>;
  ipAddress?: Maybe<Scalars['String']>;
  ipAddress_Istartswith?: Maybe<Scalars['String']>;
  latitude_Lte?: Maybe<Scalars['Float']>;
  latitude_Gte?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude_Lte?: Maybe<Scalars['Float']>;
  longitude_Gte?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
};


export type QueryDisplayGroupArgs = {
  id: Scalars['ID'];
};


export type QueryDisplayGroupsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  name_Icontains?: Maybe<Scalars['String']>;
  name_Istartswith?: Maybe<Scalars['String']>;
  isContainer?: Maybe<Scalars['Boolean']>;
};


export type QueryUserArgs = {
  id: Scalars['ID'];
};


export type QueryUsersArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  email_Icontains?: Maybe<Scalars['String']>;
};


export type QueryCustomerArgs = {
  id: Scalars['ID'];
};


export type QueryCustomersArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  name_Icontains?: Maybe<Scalars['String']>;
};


export type QueryPublicationAllocationArgs = {
  id: Scalars['ID'];
};


export type QueryPublicationAllocationsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['DateTime']>;
  startDate_Gte?: Maybe<Scalars['DateTime']>;
  startDate_Lte?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  endDate_Gte?: Maybe<Scalars['DateTime']>;
  endDate_Lte?: Maybe<Scalars['DateTime']>;
  startTime?: Maybe<Scalars['Time']>;
  startTime_Gte?: Maybe<Scalars['Time']>;
  startTime_Lte?: Maybe<Scalars['Time']>;
  endTime?: Maybe<Scalars['Time']>;
  endTime_Gte?: Maybe<Scalars['Time']>;
  endTime_Lte?: Maybe<Scalars['Time']>;
  active?: Maybe<Scalars['Boolean']>;
  active_Gte?: Maybe<Scalars['Boolean']>;
  active_Lte?: Maybe<Scalars['Boolean']>;
};


export type QueryPublicationArgs = {
  id: Scalars['ID'];
};


export type QueryPublicationsArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  name_Icontains?: Maybe<Scalars['String']>;
  name_Istartswith?: Maybe<Scalars['String']>;
};


export type QueryPublicationScreenArgs = {
  id: Scalars['ID'];
};


export type QueryPublicationScreensArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  duration?: Maybe<Scalars['Int']>;
  duration_Lte?: Maybe<Scalars['Int']>;
  duration_Gte?: Maybe<Scalars['Int']>;
};


export type QueryPublicationMediaArgs = {
  id: Scalars['ID'];
};


export type QueryPublicationMediasArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
  order_Lte?: Maybe<Scalars['Int']>;
  order_Gte?: Maybe<Scalars['Int']>;
};

export type Refresh = {
  __typename?: 'Refresh';
  payload: Scalars['GenericScalar'];
  refreshExpiresIn: Scalars['Int'];
  token: Scalars['String'];
};

export type RegisterDisplayInput = {
  pinCode: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RegisterDisplayPayload = {
  __typename?: 'RegisterDisplayPayload';
  token?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RegisterUserInput = {
  email: Scalars['String'];
  customerId: Scalars['ID'];
  profile?: Maybe<UserProfile>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RegisterUserPayload = {
  __typename?: 'RegisterUserPayload';
  user?: Maybe<UserNode>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RemoveDisplayGroupToAllocationInput = {
  id: Scalars['ID'];
  displayGroupId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RemoveDisplayGroupToAllocationPayload = {
  __typename?: 'RemoveDisplayGroupToAllocationPayload';
  publicationAllocation?: Maybe<PublicationAllocationNode>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RemoveDisplayToAllocationInput = {
  id: Scalars['ID'];
  displayId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RemoveDisplayToAllocationPayload = {
  __typename?: 'RemoveDisplayToAllocationPayload';
  publicationAllocation?: Maybe<PublicationAllocationNode>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RemoveGroupToComputerInput = {
  computerId: Scalars['ID'];
  groupId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RemoveGroupToComputerPayload = {
  __typename?: 'RemoveGroupToComputerPayload';
  displayGroup?: Maybe<DisplayComputerNode>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RemoveUserInput = {
  userId: Scalars['ID'];
  customerId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RemoveUserPayload = {
  __typename?: 'RemoveUserPayload';
  user?: Maybe<UserNode>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ResetPasswordInput = {
  userId?: Maybe<Scalars['ID']>;
  token?: Maybe<Scalars['String']>;
  newPassword?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ResetPasswordPayload = {
  __typename?: 'ResetPasswordPayload';
  success?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type SetDisplayMediaStatusInput = {
  displayId: Scalars['ID'];
  mediaId: Scalars['ID'];
  status?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type SetDisplayMediaStatusPayload = {
  __typename?: 'SetDisplayMediaStatusPayload';
  displayMediaStatus?: Maybe<DisplayMediaStatusNode>;
  clientMutationId?: Maybe<Scalars['String']>;
};


export type UpdateAdminFieldsInput = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  anydesk?: Maybe<Scalars['String']>;
  informations?: Maybe<Scalars['String']>;
  serialNumber?: Maybe<Scalars['String']>;
  designation?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateAdminFieldsPayload = {
  __typename?: 'UpdateAdminFieldsPayload';
  adminFields?: Maybe<DisplayComputerAdminFieldsNode>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateBackOfficeVersionInput = {
  id: Scalars['ID'];
  version?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['Float']>;
  dockerImageUrl?: Maybe<Scalars['String']>;
  dockerImageTag?: Maybe<Scalars['String']>;
  releaseNote?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateBackOfficeVersionPayload = {
  __typename?: 'UpdateBackOfficeVersionPayload';
  backofficeVersion?: Maybe<BackOfficeVersionNode>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateDisplayComputerInput = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['Time']>;
  endTime?: Maybe<Scalars['Time']>;
  description?: Maybe<Scalars['String']>;
  isMuted?: Maybe<Scalars['Boolean']>;
  ipAddress?: Maybe<Scalars['String']>;
  browserId?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Decimal']>;
  longitude?: Maybe<Scalars['Decimal']>;
  isActive?: Maybe<Scalars['Boolean']>;
  monday?: Maybe<Scalars['Boolean']>;
  tuesday?: Maybe<Scalars['Boolean']>;
  wednesday?: Maybe<Scalars['Boolean']>;
  thursday?: Maybe<Scalars['Boolean']>;
  friday?: Maybe<Scalars['Boolean']>;
  saturday?: Maybe<Scalars['Boolean']>;
  sunday?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateDisplayComputerPayload = {
  __typename?: 'UpdateDisplayComputerPayload';
  displayComputer?: Maybe<DisplayComputerNode>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateDisplayGroupInput = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  isContainer?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateDisplayGroupPayload = {
  __typename?: 'UpdateDisplayGroupPayload';
  displayGroup?: Maybe<DisplayGroupNode>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateDisplayInput = {
  id: Scalars['ID'];
  offsetX?: Maybe<Scalars['Int']>;
  offsetY?: Maybe<Scalars['Int']>;
  widthPx?: Maybe<Scalars['Int']>;
  heightPx?: Maybe<Scalars['Int']>;
  pitch?: Maybe<Scalars['Float']>;
  orientation?: Maybe<Scalars['Int']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateDisplayPayload = {
  __typename?: 'UpdateDisplayPayload';
  display?: Maybe<DisplayNode>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateLayoutItemInput = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  x?: Maybe<Scalars['String']>;
  y?: Maybe<Scalars['String']>;
  z?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['String']>;
  widgetData?: Maybe<Scalars['JSONString']>;
  animations?: Maybe<Scalars['JSONString']>;
  scale?: Maybe<Scalars['Float']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateLayoutItemPayload = {
  __typename?: 'UpdateLayoutItemPayload';
  layoutItem?: Maybe<LayoutItemNode>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdatePlayerVersionInput = {
  id: Scalars['ID'];
  version?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['Float']>;
  date?: Maybe<Scalars['String']>;
  dockerImageUrl?: Maybe<Scalars['String']>;
  dockerImageTag?: Maybe<Scalars['String']>;
  releaseNote?: Maybe<Scalars['String']>;
  minimumBackofficeVersion?: Maybe<Scalars['Float']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdatePlayerVersionPayload = {
  __typename?: 'UpdatePlayerVersionPayload';
  playerVersion?: Maybe<PlayerVersionNode>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdatePublicationAllocationInput = {
  id: Scalars['ID'];
  priority?: Maybe<PriorityEnum>;
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
  startTime?: Maybe<Scalars['Time']>;
  endTime?: Maybe<Scalars['Time']>;
  active?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdatePublicationAllocationPayload = {
  __typename?: 'UpdatePublicationAllocationPayload';
  publicationAllocation?: Maybe<PublicationAllocationNode>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdatePublicationInput = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdatePublicationMediaInput = {
  id: Scalars['ID'];
  order?: Maybe<Scalars['Int']>;
  duration?: Maybe<Scalars['Int']>;
  objectFit?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Date']>;
  endDate?: Maybe<Scalars['Date']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdatePublicationMediaPayload = {
  __typename?: 'UpdatePublicationMediaPayload';
  publicationMedia?: Maybe<PublicationMediaNode>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdatePublicationPayload = {
  __typename?: 'UpdatePublicationPayload';
  publication?: Maybe<PublicationNode>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdatePublicationScreenInput = {
  id: Scalars['ID'];
  layoutId?: Maybe<Scalars['ID']>;
  duration?: Maybe<Scalars['Int']>;
  backgroundColor?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdatePublicationScreenPayload = {
  __typename?: 'UpdatePublicationScreenPayload';
  publicationScreen?: Maybe<PublicationScreenNode>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateUserInput = {
  userId: Scalars['ID'];
  profile?: Maybe<UserProfile>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateUserPayload = {
  __typename?: 'UpdateUserPayload';
  user?: Maybe<UserNode>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateWidgetMediaInput = {
  id: Scalars['ID'];
  data?: Maybe<Scalars['JSONString']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateWidgetMediaPayload = {
  __typename?: 'UpdateWidgetMediaPayload';
  widgetMedia?: Maybe<WidgetMediaNode>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UserNode = Node & {
  __typename?: 'UserNode';
  /** The ID of the object */
  id: Scalars['ID'];
  lastLogin?: Maybe<Scalars['DateTime']>;
  /** Designates that this user has all permissions without explicitly assigning them. */
  isSuperuser: Scalars['Boolean'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  /** Designates whether the user can log into this admin site. */
  isStaff: Scalars['Boolean'];
  /** Designates whether this user should be treated as active. Unselect this instead of deleting accounts. */
  isActive: Scalars['Boolean'];
  dateJoined: Scalars['DateTime'];
  avatar: Scalars['String'];
  customers: CustomerNodeConnection;
  email: Scalars['String'];
  profile?: Maybe<UserProfile>;
};


export type UserNodeCustomersArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  name_Icontains?: Maybe<Scalars['String']>;
};

export type UserNodeConnection = {
  __typename?: 'UserNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<UserNodeEdge>>;
  totalCount?: Maybe<Scalars['Int']>;
  edgeCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `UserNode` and its cursor. */
export type UserNodeEdge = {
  __typename?: 'UserNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<UserNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export enum UserProfile {
  Admin = 'ADMIN',
  ParkManager = 'PARK_MANAGER',
  ContentManager = 'CONTENT_MANAGER'
}

export type Verify = {
  __typename?: 'Verify';
  payload: Scalars['GenericScalar'];
};

export type WidgetLayoutItemNode = Node & {
  __typename?: 'WidgetLayoutItemNode';
  /** The ID of the object */
  id: Scalars['ID'];
  widgetType: WidgetNode;
  layoutItem: LayoutItemNode;
  data?: Maybe<Scalars['JSONString']>;
  widgetmediaSet: WidgetMediaNodeConnection;
};


export type WidgetLayoutItemNodeWidgetmediaSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  widgetLayoutItem?: Maybe<Scalars['ID']>;
};

export type WidgetLayoutItemNodeConnection = {
  __typename?: 'WidgetLayoutItemNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<WidgetLayoutItemNodeEdge>>;
  totalCount?: Maybe<Scalars['Int']>;
  edgeCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `WidgetLayoutItemNode` and its cursor. */
export type WidgetLayoutItemNodeEdge = {
  __typename?: 'WidgetLayoutItemNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<WidgetLayoutItemNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type WidgetMediaNode = Node & {
  __typename?: 'WidgetMediaNode';
  /** The ID of the object */
  id: Scalars['ID'];
  widgetLayoutItem: WidgetLayoutItemNode;
  mediaContent: MediaContentNode;
  data?: Maybe<Scalars['JSONString']>;
};

export type WidgetMediaNodeConnection = {
  __typename?: 'WidgetMediaNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<WidgetMediaNodeEdge>>;
  totalCount?: Maybe<Scalars['Int']>;
  edgeCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `WidgetMediaNode` and its cursor. */
export type WidgetMediaNodeEdge = {
  __typename?: 'WidgetMediaNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<WidgetMediaNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type WidgetNode = Node & {
  __typename?: 'WidgetNode';
  /** The ID of the object */
  id: Scalars['ID'];
  name: Scalars['String'];
  package: Scalars['String'];
  description: Scalars['String'];
  config?: Maybe<Scalars['JSONString']>;
  isActive: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  customers: CustomerNodeConnection;
  minimumBackofficeVersion: Scalars['Float'];
  widgetlayoutitemSet: WidgetLayoutItemNodeConnection;
};


export type WidgetNodeCustomersArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  name_Icontains?: Maybe<Scalars['String']>;
};


export type WidgetNodeWidgetlayoutitemSetArgs = {
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type WidgetNodeConnection = {
  __typename?: 'WidgetNodeConnection';
  /** Pagination data for this connection. */
  pageInfo: PageInfo;
  /** Contains the nodes in this connection. */
  edges: Array<Maybe<WidgetNodeEdge>>;
  totalCount?: Maybe<Scalars['Int']>;
  edgeCount?: Maybe<Scalars['Int']>;
};

/** A Relay edge containing a `WidgetNode` and its cursor. */
export type WidgetNodeEdge = {
  __typename?: 'WidgetNodeEdge';
  /** The item at the end of the edge */
  node?: Maybe<WidgetNode>;
  /** A cursor for use in pagination */
  cursor: Scalars['String'];
};

export type MediaContentFragment = (
  { __typename?: 'MediaContentNode' }
  & Pick<MediaContentNode, 'id' | 'contentType' | 'name' | 'signedUrl' | 'webpageUrl' | 'fallbackUrl' | 'fallbackRefreshPeriod' | 'thumbnailUrl' | 'contentConverted' | 'sha256'>
);

export type WidgetMediaFragment = (
  { __typename?: 'WidgetMediaNode' }
  & Pick<WidgetMediaNode, 'id' | 'data'>
  & { mediaContent: (
    { __typename?: 'MediaContentNode' }
    & MediaContentFragment
  ) }
);

export type LayoutItemFragment = (
  { __typename?: 'LayoutItemNode' }
  & Pick<LayoutItemNode, 'id' | 'name' | 'x' | 'y' | 'z' | 'width' | 'height' | 'alpha' | 'animations' | 'scale'>
  & { widget?: Maybe<(
    { __typename?: 'WidgetLayoutItemNode' }
    & Pick<WidgetLayoutItemNode, 'id' | 'data'>
    & { widgetType: (
      { __typename?: 'WidgetNode' }
      & Pick<WidgetNode, 'id' | 'name'>
    ), widgetmediaSet: (
      { __typename?: 'WidgetMediaNodeConnection' }
      & { edges: Array<Maybe<(
        { __typename?: 'WidgetMediaNodeEdge' }
        & { node?: Maybe<(
          { __typename?: 'WidgetMediaNode' }
          & WidgetMediaFragment
        )> }
      )>> }
    ) }
  )> }
);

export type PublicationScreenFragment = (
  { __typename?: 'PublicationScreenNode' }
  & Pick<PublicationScreenNode, 'id' | 'backgroundColor' | 'duration'>
  & { layoutitemSet: (
    { __typename?: 'LayoutItemNodeConnection' }
    & { edges: Array<Maybe<(
      { __typename?: 'LayoutItemNodeEdge' }
      & { node?: Maybe<(
        { __typename?: 'LayoutItemNode' }
        & LayoutItemFragment
      )> }
    )>> }
  ) }
);

export type PublicationFragment = (
  { __typename?: 'PublicationNode' }
  & Pick<PublicationNode, 'id' | 'name' | 'updatedAt'>
  & { publicationscreenSet: (
    { __typename?: 'PublicationScreenNodeConnection' }
    & { edges: Array<Maybe<(
      { __typename?: 'PublicationScreenNodeEdge' }
      & { node?: Maybe<(
        { __typename?: 'PublicationScreenNode' }
        & PublicationScreenFragment
      )> }
    )>> }
  ) }
);

export type AllocationFragment = (
  { __typename?: 'PublicationAllocationNode' }
  & Pick<PublicationAllocationNode, 'id' | 'startTime' | 'endTime'>
  & { publication: (
    { __typename?: 'PublicationNode' }
    & PublicationFragment
  ) }
);

export type PlayerSoftwareVersionFragment = (
  { __typename?: 'PlayerVersionNode' }
  & Pick<PlayerVersionNode, 'id' | 'version' | 'date' | 'number' | 'dockerImageUrl' | 'dockerImageTag'>
);

export type GetDisplayProgramQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDisplayProgramQuery = (
  { __typename?: 'Query' }
  & { displayProgram?: Maybe<(
    { __typename?: 'DisplayNode' }
    & Pick<DisplayNode, 'id' | 'offsetX' | 'offsetY' | 'widthPx' | 'heightPx'>
    & { computer: (
      { __typename?: 'DisplayComputerNode' }
      & Pick<DisplayComputerNode, 'isMuted' | 'id' | 'name' | 'startTime' | 'endTime' | 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday' | 'sunday'>
      & { playerVersion?: Maybe<(
        { __typename?: 'PlayerVersionNode' }
        & PlayerSoftwareVersionFragment
      )>, groups: (
        { __typename?: 'DisplayGroupNodeConnection' }
        & { edges: Array<Maybe<(
          { __typename?: 'DisplayGroupNodeEdge' }
          & { node?: Maybe<(
            { __typename?: 'DisplayGroupNode' }
            & { publicationallocationSet: (
              { __typename?: 'PublicationAllocationNodeConnection' }
              & { edges: Array<Maybe<(
                { __typename?: 'PublicationAllocationNodeEdge' }
                & { node?: Maybe<(
                  { __typename?: 'PublicationAllocationNode' }
                  & AllocationFragment
                )> }
              )>> }
            ) }
          )> }
        )>> }
      ) }
    ), publicationallocationSet: (
      { __typename?: 'PublicationAllocationNodeConnection' }
      & { edges: Array<Maybe<(
        { __typename?: 'PublicationAllocationNodeEdge' }
        & { node?: Maybe<(
          { __typename?: 'PublicationAllocationNode' }
          & AllocationFragment
        )> }
      )>> }
    ) }
  )> }
);

export type RegisterDisplayMutationVariables = Exact<{
  pinCode: Scalars['String'];
}>;


export type RegisterDisplayMutation = (
  { __typename?: 'Mutation' }
  & { registerDisplay?: Maybe<(
    { __typename?: 'RegisterDisplayPayload' }
    & Pick<RegisterDisplayPayload, 'token'>
  )> }
);

export const MediaContentFragmentDoc = gql`
    fragment MediaContent on MediaContentNode {
  id
  contentType
  name
  signedUrl
  webpageUrl
  fallbackUrl
  fallbackRefreshPeriod
  thumbnailUrl
  contentConverted
  sha256
}
    `;
export const WidgetMediaFragmentDoc = gql`
    fragment WidgetMedia on WidgetMediaNode {
  id
  mediaContent {
    ...MediaContent
  }
  data
}
    ${MediaContentFragmentDoc}`;
export const LayoutItemFragmentDoc = gql`
    fragment LayoutItem on LayoutItemNode {
  id
  name
  x
  y
  z
  width
  height
  alpha
  animations
  scale
  widget {
    id
    widgetType {
      id
      name
    }
    data
    widgetmediaSet {
      edges {
        node {
          ...WidgetMedia
        }
      }
    }
  }
  animations
}
    ${WidgetMediaFragmentDoc}`;
export const PublicationScreenFragmentDoc = gql`
    fragment PublicationScreen on PublicationScreenNode {
  id
  backgroundColor
  duration
  layoutitemSet {
    edges {
      node {
        ...LayoutItem
      }
    }
  }
}
    ${LayoutItemFragmentDoc}`;
export const PublicationFragmentDoc = gql`
    fragment Publication on PublicationNode {
  id
  name
  updatedAt
  publicationscreenSet {
    edges {
      node {
        ...PublicationScreen
      }
    }
  }
}
    ${PublicationScreenFragmentDoc}`;
export const AllocationFragmentDoc = gql`
    fragment Allocation on PublicationAllocationNode {
  id
  startTime
  endTime
  publication {
    ...Publication
  }
}
    ${PublicationFragmentDoc}`;
export const PlayerSoftwareVersionFragmentDoc = gql`
    fragment PlayerSoftwareVersion on PlayerVersionNode {
  id
  version
  date
  number
  dockerImageUrl
  dockerImageTag
}
    `;
export const GetDisplayProgramDocument = gql`
    query GetDisplayProgram {
  displayProgram {
    id
    offsetX
    offsetY
    widthPx
    heightPx
    computer {
      isMuted
      playerVersion {
        ...PlayerSoftwareVersion
      }
      id
      name
      startTime
      endTime
      monday
      tuesday
      wednesday
      thursday
      friday
      saturday
      sunday
      groups {
        edges {
          node {
            publicationallocationSet {
              edges {
                node {
                  ...Allocation
                }
              }
            }
          }
        }
      }
    }
    publicationallocationSet {
      edges {
        node {
          ...Allocation
        }
      }
    }
    computer {
      id
      name
      startTime
      endTime
      monday
      tuesday
      wednesday
      thursday
      friday
      saturday
      sunday
      groups {
        edges {
          node {
            publicationallocationSet {
              edges {
                node {
                  ...Allocation
                }
              }
            }
          }
        }
      }
    }
  }
}
    ${PlayerSoftwareVersionFragmentDoc}
${AllocationFragmentDoc}`;

/**
 * __useGetDisplayProgramQuery__
 *
 * To run a query within a React component, call `useGetDisplayProgramQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDisplayProgramQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDisplayProgramQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDisplayProgramQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDisplayProgramQuery, GetDisplayProgramQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetDisplayProgramQuery, GetDisplayProgramQueryVariables>(GetDisplayProgramDocument, options);
      }
export function useGetDisplayProgramLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDisplayProgramQuery, GetDisplayProgramQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetDisplayProgramQuery, GetDisplayProgramQueryVariables>(GetDisplayProgramDocument, options);
        }
export type GetDisplayProgramQueryHookResult = ReturnType<typeof useGetDisplayProgramQuery>;
export type GetDisplayProgramLazyQueryHookResult = ReturnType<typeof useGetDisplayProgramLazyQuery>;
export type GetDisplayProgramQueryResult = ApolloReactCommon.QueryResult<GetDisplayProgramQuery, GetDisplayProgramQueryVariables>;
export const RegisterDisplayDocument = gql`
    mutation RegisterDisplay($pinCode: String!) {
  registerDisplay(input: {pinCode: $pinCode}) {
    token
  }
}
    `;
export type RegisterDisplayMutationFn = ApolloReactCommon.MutationFunction<RegisterDisplayMutation, RegisterDisplayMutationVariables>;

/**
 * __useRegisterDisplayMutation__
 *
 * To run a mutation, you first call `useRegisterDisplayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterDisplayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerDisplayMutation, { data, loading, error }] = useRegisterDisplayMutation({
 *   variables: {
 *      pinCode: // value for 'pinCode'
 *   },
 * });
 */
export function useRegisterDisplayMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RegisterDisplayMutation, RegisterDisplayMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<RegisterDisplayMutation, RegisterDisplayMutationVariables>(RegisterDisplayDocument, options);
      }
export type RegisterDisplayMutationHookResult = ReturnType<typeof useRegisterDisplayMutation>;
export type RegisterDisplayMutationResult = ApolloReactCommon.MutationResult<RegisterDisplayMutation>;
export type RegisterDisplayMutationOptions = ApolloReactCommon.BaseMutationOptions<RegisterDisplayMutation, RegisterDisplayMutationVariables>;