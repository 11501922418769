import { Image } from "@chakra-ui/react";
import { FC, useContext, useEffect } from "react";
import { MediaProps } from ".";
import { PersistedMediaContext } from "../../../components/PersistedMedia";
import { parseMediaMetaData } from "../metaData";

export const ImageMedia: FC<MediaProps> = ({
  widgetMedia,
  offlineMedia,
  nextMedia,
}) => {
  const { onMediaError } = useContext(PersistedMediaContext);
  const mediaMetaData = parseMediaMetaData(widgetMedia.data);
  useEffect(() => {
    const timeout = setTimeout(nextMedia, mediaMetaData.duration * 1000);
    return () => clearTimeout(timeout);
  }, [mediaMetaData, nextMedia]);
  return (
    <Image
      width="100%"
      height="100%"
      objectFit={
        (mediaMetaData.object_fit || "fill") as "fill" | "contain" | "cover"
      }
      onError={(e) => {
        onMediaError(offlineMedia);
      }}
      src={offlineMedia!.offlineUrl || ""}
    />
  );
};
