import React, {
  FunctionComponent,
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { PersistedMediaContext } from "../../components/PersistedMedia";
import {
  LayoutItemFragment,
  MediaMediaContentContentTypeChoices,
  WidgetMediaFragment,
  WidgetMediaNode,
} from "../../generated/graphql";
import { useNavigatorOnlineStatus } from "../../libs/navigatorOnline";
import { MediaStatus } from "../../libs/persistMedia";
import { PlatformLogging } from "../../libs/platformAPI";
import { MediaMetaDataType, parseMediaMetaData } from "./metaData";

type PlaylistContextType = {
  currentMedia: null | WidgetMediaFragment;
  nextMedia: () => void;
  isOnline: Boolean;
};

export const PlaylistContext = React.createContext<PlaylistContextType>({
  currentMedia: null,
  nextMedia: () => null,
  isOnline: true,
});

type PlaylistContextProviderProps = {
  layoutItem: LayoutItemFragment;
  onComplete?: () => void;
};

export const PlaylistContextProvider: FunctionComponent<PlaylistContextProviderProps> =
  memo(({ children, layoutItem, onComplete }) => {
    const [mediaIndex, setMediaIndex] = useState(0);
    const { persistedMedia } = useContext(PersistedMediaContext);
    const widgetMediaList = layoutItem.widget?.widgetmediaSet?.edges;
    const widgetMediaListSignture = widgetMediaList
      ?.map((media) => media?.node?.id + media?.node?.data)
      .join(",");
    const mediaList = useMemo(() => {
      console.log("build mediaList in contextDevice");
      return (
        widgetMediaList
          ?.map((edge) => edge?.node)
          // .filter((media) => media === null && media !== undefined)
          .filter(
            // if media is a file (not a webpage) is offline
            (widgetMedia) =>
              widgetMedia!.mediaContent.contentType ===
                MediaMediaContentContentTypeChoices.If ||
              persistedMedia[widgetMedia!.mediaContent.id]?.status ===
                MediaStatus.READY
          )
          .filter((widgetMedia) =>
            isMediaNotExpired(parseMediaMetaData(widgetMedia!.data))
          )
          .filter((widgetMedia) =>
            isMediaReady(parseMediaMetaData(widgetMedia!.data))
          )
          .sort((a, b) => {
            const aMetaData = parseMediaMetaData(a?.data);
            const bMetaData = parseMediaMetaData(b?.data);
            return (aMetaData.order || 0) - (bMetaData.order || 0);
          }) || []
      );
    }, [widgetMediaListSignture, persistedMedia]);

    const mediaListSignture = mediaList.map((media) => media?.id).join(",");

    const onLine = useNavigatorOnlineStatus();
    const currentMedia = (mediaList[mediaIndex] as WidgetMediaFragment) || null;

    const nextMedia = useCallback(() => {
      const nextMediaIndex =
        mediaIndex + 1 >= mediaList.length ? 0 : mediaIndex + 1;
      if (nextMediaIndex === 0) {
        onComplete && onComplete();
      }
      PlatformLogging.getLogger().info({
        message: "Media Rotation",
        playlistId: layoutItem.id,
        currentMediaId: currentMedia?.id,
        nextMediaId: mediaList[nextMediaIndex]?.id,
        index: nextMediaIndex,
      });
      setMediaIndex(nextMediaIndex);
    }, [
      mediaIndex,
      setMediaIndex,
      mediaList,
      onComplete,
      currentMedia,
      layoutItem,
    ]);

    // If no connection and media is iframe, go next media
    useEffect(() => {
      if (
        currentMedia?.mediaContent?.contentType ===
          MediaMediaContentContentTypeChoices.If &&
        !onLine
      ) {
        PlatformLogging.getLogger().error({ onLine, message: "No Internet" });
        // Until we have offline mode on iframe... don't show error on loading
        const timeout = setTimeout(nextMedia, 500);
        return () => {
          if (timeout) {
            clearTimeout(timeout);
          }
        };
      }
    }, [currentMedia, onLine, nextMedia]);

    useEffect(() => {
      if (mediaList.length === 0) {
        console.log("No media in playlist");
        PlatformLogging.getLogger().warn({
          onLine,
          message: "No media in playlist",
        });
        const timeout = setTimeout(() => onComplete && onComplete(), 500);
        return () => {
          if (timeout) {
            clearTimeout(timeout);
          }
        };
      }
    }, [mediaListSignture, onComplete]);

    return (
      <PlaylistContext.Provider
        value={{
          currentMedia,
          isOnline: onLine,
          nextMedia,
        }}
      >
        {children}
      </PlaylistContext.Provider>
    );
  });

const isMediaNotExpired = (media: MediaMetaDataType) =>
  media.end_date === null ||
  media.end_date === undefined ||
  media.end_date.getTime() + 3600 * 1000 * 24 >= new Date().getTime();

const isMediaReady = (media: MediaMetaDataType) =>
  media.start_date === null ||
  media.start_date === undefined ||
  media.start_date.getTime() <= new Date().getTime();
