const VERSION_COOKIE_KEY = "software_version";

export const getSotfwareVersionCookies = () =>
  document.cookie
    .split("; ")
    .filter((row) => row.startsWith(VERSION_COOKIE_KEY));

export const getSotfwareVersionCookie = () => {
  const cookies = getSotfwareVersionCookies();
  if (cookies.length === 0) {
    return undefined;
  }
  const cookie = cookies[0];
  const [cookieName] = cookie.split("=");
  return cookieName.replace(VERSION_COOKIE_KEY + "_", "").replace(/-/g, ".");
};

export const setSoftwareVersionCookie = (version: string) => {
  getSotfwareVersionCookies().forEach((cookie) => {
    const cookieName = cookie.split("=")[0];
    document.cookie = `${cookieName}=never; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  });
  let formatedVersion = version.replace(/\./g, "-");
  console.log(
    "updateCookie:",
    `${VERSION_COOKIE_KEY}_${version}=always; path=/`
  );
  document.cookie = `${VERSION_COOKIE_KEY}_${formatedVersion}=always; path=/`;
};

export const handleVersion = (version: string) => {
  const cookieVersion = getSotfwareVersionCookie();
  console.log("cookieVersion", cookieVersion);
  if (cookieVersion !== version) {
    setSoftwareVersionCookie(version);
    window.location.reload();
  }
};
