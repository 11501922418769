import moment from "moment";
import { useEffect, useState } from "react";

export const inTimeRange = (
  startTime: string | null,
  endTime: string | null,
  checkTime: Date
) => {
  let start =
    startTime === null
      ? moment("0:00:00", "h:mm:ss")
      : moment(startTime, "H:mm:ss");
  let end =
    endTime === null
      ? moment("23:59:59", "h:mm:ss")
      : moment(endTime, "H:mm:ss");
  let now = moment(checkTime);
  if (end < start) {
    return (
      (now >= start && now <= moment("23:59:59", "h:mm:ss")) ||
      (now >= moment("0:00:00", "h:mm:ss") && now < end)
    );
  }
  return now >= start && now < end;
};

export const useInTimeRange = (
  startTime: string | null,
  endTime: string | null,
  refreshPeriod = 1000
) => {
  const [isInRange, setInRange] = useState(true);
  useEffect(() => {
    const loop = setInterval(
      () => setInRange(inTimeRange(startTime, endTime, new Date())),
      refreshPeriod
    );
    return () => {
      clearInterval(loop);
    };
  }, [startTime, endTime, refreshPeriod]);
  return isInRange;
};

export type ActiveDays = {
  monday: boolean | null;
  tuesday: boolean | null;
  wednesday: boolean | null;
  thursday: boolean | null;
  friday: boolean | null;
  saturday: boolean | null;
  sunday: boolean | null;
};

export type Day =
  | "monday"
  | "tuesday"
  | "wednesday"
  | "thursday"
  | "friday"
  | "saturday"
  | "sunday";

export const DAYS: Day[] = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
];

export const getDayOfTheWeek = () => {
  const today = new Date().getDay();
  return DAYS[today];
};
