import { MediaContentFragment } from "../generated/graphql";
import localforage from "localforage";
import { useEffect, useState, useMemo } from "react";
import { PlatformLogging } from "./platformAPI";

export enum MediaStatus {
  WAITING = 0,
  DOWNLOADED = 1,
  RETRYING = 2,
  READY = 3,
  MISMATCH = 4,
  ERROR = 5,
}

export type PersitedMedia = {
  node: MediaContentFragment;
  offlineUrl: string | null;
  status: MediaStatus;
  nbRetries: number;
};

export type PersistedMediaState = { [key: string]: PersitedMedia };

export type ProvidedPersistedMedia = {
  persistedMedia: PersistedMediaState;
  setPersistedMedia: React.Dispatch<React.SetStateAction<PersistedMediaState>>;
  onMediaError: (offlineMedia: PersitedMedia) => void;
};

const downloadContent = async (url: string) => {
  const res = await fetch(url);
  if (res.ok) {
    return await res.blob();
  }
  return null;
};

const hashFile = async (file: Blob) => {
  const arrayBufer = await file.arrayBuffer();
  const digest = await crypto.subtle.digest("SHA-256", arrayBufer);
  // convert array buffer to hexa string
  return Array.from(new Uint8Array(digest))
    .map((b) => b.toString(16).padStart(2, "0"))
    .join("");
};

export const checkSHA256 = (
  serverMediaHash: string | null | undefined,
  persistedMediaHash: string
) => {
  if (serverMediaHash === null) {
    // for compatibility with old versions that doesn't have sha yet.
    return true;
  }
  return persistedMediaHash === serverMediaHash;
};

const storeMedia = async (media: PersitedMedia) => {
  try {
    if (media.node.signedUrl) {
      const contentBlob = await downloadContent(media.node.signedUrl);
      if (contentBlob) {
        PlatformLogging.getLogger().info({
          message: "Media completly downloaded",
          media: media.node.id,
        });
        const itemContent = await localforage.setItem(
          media.node.id,
          contentBlob
        );

        PlatformLogging.getLogger().info({
          message: "Media completly persisted",
          media: media.node.id,
        });
        const sha256 = await hashFile(contentBlob);
        PlatformLogging.getLogger().info({
          message: "Media local sha256",
          media: media.node.id,
          sha256,
        });
        await localforage.setItem(media.node.id + "_hash", sha256);
        PlatformLogging.getLogger().info({
          message: "Media sha256 persisted",
          media: media.node.id,
        });
        return itemContent;
      }
    }
  } catch (e) {
    console.error(e);
  }
};

export const handleMedia = async (
  media: PersitedMedia
): Promise<PersitedMedia> => {
  let mediaContent = await localforage.getItem(media.node.id);
  if (media.status === MediaStatus.RETRYING) {
    if (media.nbRetries >= 3) {
      return {
        ...media,
        offlineUrl: null,
        status: MediaStatus.MISMATCH,
      };
    }
    mediaContent = await storeMedia(media);
    media.status = MediaStatus.DOWNLOADED;
  } else if (media.status === MediaStatus.WAITING) {
    if (mediaContent === null) {
      mediaContent = await storeMedia(media);
    }
    media.status = MediaStatus.DOWNLOADED;
  }

  if (
    media.status === MediaStatus.DOWNLOADED ||
    media.status === MediaStatus.READY
  ) {
    const existingItemHash: string | null = await localforage.getItem(
      media.node.id + "_hash"
    );
    if (!checkSHA256(media.node.sha256, existingItemHash || "")) {
      try {
        await localforage.removeItem(media.node.id);
        await localforage.removeItem(media.node.id + "_hash");
      } catch (e) {
        console.error(e);
      }
      return {
        ...media,
        offlineUrl: null,
        status: MediaStatus.RETRYING,
        nbRetries: media.nbRetries + 1,
      };
    } else {
      if (mediaContent) {
        return {
          ...media,
          offlineUrl: window.URL.createObjectURL(mediaContent as Blob),
          status: MediaStatus.READY,
        };
      } else {
        return media;
      }
    }
  } else {
    return media;
  }
};

const reduceMedias = (medias: MediaContentFragment[]): PersistedMediaState =>
  medias.reduce(
    (prev, media) => ({
      ...prev,
      [media.id]: {
        node: media,
        offlineUrl: null,
        status: MediaStatus.WAITING,
        nbRetries: 0,
      },
    }),
    {}
  );

export const persistedMediaSignature = (media: PersitedMedia) =>
  media.node.id + "s:" + media.status + "sha256" + media.node.sha256;

export const mediaSignature = (media: MediaContentFragment) =>
  media.id + "sha256:" + media.sha256;

export const usePersistedMedias = (mediaList: MediaContentFragment[]) => {
  const [persistedMediaList, setPersistedMediaList] =
    useState<PersistedMediaState>(() => reduceMedias(mediaList));

  const persistedMediaListSignature = Object.values(persistedMediaList)
    .map(persistedMediaSignature)
    .join("");

  const mediaListSignature = mediaList.map(mediaSignature).join("");

  useEffect(() => {
    PlatformLogging.getLogger().info({
      message: "Update media list",
    });
    setPersistedMediaList((prev) => {
      let newPersistedMediaList: PersistedMediaState = {};
      for (const media of mediaList) {
        const persistedMedia = prev[media.id];
        let newPersistedData: PersitedMedia;
        if (persistedMedia) {
          newPersistedData = {
            ...persistedMedia,
            node: media,
          };
          if (persistedMedia.status === MediaStatus.MISMATCH) {
            if (persistedMedia.node.sha256 !== media.sha256) {
              // give another try as the sha has changed
              newPersistedData["status"] = MediaStatus.RETRYING;
              newPersistedData["nbRetries"] = 0;
            }
          }
        } else {
          newPersistedData = {
            node: media,
            offlineUrl: null,
            status: MediaStatus.WAITING,
            nbRetries: 0,
          };
        }
        newPersistedMediaList[media.id] = newPersistedData;
      }
      return newPersistedMediaList;
    });
  }, [mediaListSignature, setPersistedMediaList]);

  const onMediaError = (offlineMedia: PersitedMedia) => {
    PlatformLogging.getLogger().error({
      message: "Retrying on media error",
      media: offlineMedia,
    });
    if (
      offlineMedia &&
      offlineMedia.status === MediaStatus.READY &&
      offlineMedia.nbRetries < 3
    ) {
      provided.setPersistedMedia((prevState) => ({
        ...prevState,
        [offlineMedia!.node!.id]: {
          ...prevState[offlineMedia!.node!.id],
          status: MediaStatus.ERROR,
        } as PersitedMedia,
      }));
    }
  };

  useEffect(() => {
    async function updatePersistedMedia() {
      let newPersistedMediaState = { ...persistedMediaList };
      for (const mediaId in persistedMediaList) {
        newPersistedMediaState[mediaId] = await handleMedia(
          persistedMediaList[mediaId]
        );
      }
      setPersistedMediaList(newPersistedMediaState);
    }
    updatePersistedMedia();
  }, [persistedMediaListSignature, setPersistedMediaList]);

  const provided = useMemo(
    () => ({
      persistedMedia: persistedMediaList,
      setPersistedMedia: setPersistedMediaList,
      onMediaError: onMediaError,
    }),
    [mediaListSignature, persistedMediaListSignature, setPersistedMediaList]
  );

  return provided;
};
