import { useEffect, useState } from "react";

export const useNavigatorOnlineStatus = () => {
  const [onLine, setOnLine] = useState(navigator.onLine);
  useEffect(() => {
    const event = setInterval(() => setOnLine(navigator.onLine));
    return () => clearInterval(event);
  });
  return onLine;
};
