import { Box } from "@chakra-ui/layout";

import { useCallback, useState, useEffect } from "react";
import { Player } from "../components/Player";
import {
  MediaContentFragment,
  MediaMediaContentContentTypeChoices,
  PublicationAllocationNodeEdge,
  PublicationFragment,
  useGetDisplayProgramQuery,
} from "../generated/graphql";
import useWebSocket from "react-use-websocket";
import {
  handlePlatformMessage,
  PlatformAPI,
  PlatformLogging,
  useHeartBeat,
  useUpdateSchedule,
} from "../libs/platformAPI";
import {
  ActiveDays,
  DAYS,
  getDayOfTheWeek,
  inTimeRange,
  useInTimeRange,
} from "../libs/schedule";
import { PersistedMedia } from "../components/PersistedMedia";
import { getDisplayToken } from "../libs/auth";
import { getSotfwareVersionCookie, handleVersion } from "../libs/versionCookie";

const publicationSignature = (publication: PublicationFragment) => {
  return publication.id + publication.updatedAt;
};

const listPublicationsFromAllocations = (
  allocations: PublicationAllocationNodeEdge[],
  now: Date
) => {
  return allocations
    .filter((allocation) =>
      inTimeRange(allocation?.node?.startTime, allocation?.node?.endTime, now)
    )
    .map((allocation) => allocation?.node?.publication)
    .filter((publication) => publication !== null);
};

const useGetFilteredPublications = () => {
  const [publications, setPublications] = useState<PublicationFragment[]>();
  const { data, startPolling, stopPolling } = useGetDisplayProgramQuery({
    onError: (error) => {
      PlatformLogging.getLogger().error({
        error,
      });
    },
  });

  useEffect(() => {
    startPolling(20000);
    const loop = setInterval(() => {
      const now = new Date();
      const filteredPublications = [
        // retrieve publications allocated directly to this display.
        ...listPublicationsFromAllocations(
          (data?.displayProgram?.publicationallocationSet.edges ||
            []) as PublicationAllocationNodeEdge[],
          now
        ),
        // retrieve publications allocated to one of display's groups.
        ...listPublicationsFromAllocations(
          (data?.displayProgram?.computer.groups.edges
            .map((group) => group?.node?.publicationallocationSet.edges)
            .flat() || []) as PublicationAllocationNodeEdge[],
          now
        ),
      ] as PublicationFragment[];
      if (
        filteredPublications
          .map((pub) => publicationSignature(pub))
          .toString() !==
        publications?.map((pub) => publicationSignature(pub)).toString()
      ) {
        PlatformLogging.getLogger().info({
          message: "update publications...",
          filteredPublications: filteredPublications
            .map((pub) => pub.id)
            .toString(),
          publications: publications?.map((pub) => pub.id).toString(),
        });
        setPublications(filteredPublications);
      }
    }, 1000);
    return () => {
      clearInterval(loop);
      stopPolling();
    };
  }, [data, publications, startPolling, stopPolling]);

  return { displayProgram: data?.displayProgram, publications };
};

export const PublicationReader = () => {
  const [publicationIndex, setPublicationIndex] = useState<number>(0);
  const { sendJsonMessage, readyState } = useWebSocket("ws://localhost:8765", {
    onMessage: handlePlatformMessage,
  });
  useHeartBeat(sendJsonMessage, readyState);
  PlatformLogging.init(sendJsonMessage, readyState);
  PlatformAPI.init(sendJsonMessage, readyState);

  const { displayProgram, publications } = useGetFilteredPublications();
  console.log("displayProgram", displayProgram);
  if (displayProgram) {
    getDisplayToken().then((displayToken) =>
      sendJsonMessage({
        type: "INFO",
        data: {
          type: "DEVICE_PARAMS",
          data: {
            computerId: displayProgram.computer.id,
            computerName: displayProgram.computer.name,
            displayId: displayProgram.id,
            isMuted: displayProgram.computer.isMuted,
            ...(displayToken ? { displayToken: displayToken } : {}),
          },
        },
      })
    );
  }

  const mediaList =
    publications
      ?.map((publications) =>
        publications.publicationscreenSet?.edges.map((publicationScreen) =>
          publicationScreen?.node?.layoutitemSet.edges.map((layoutItem) =>
            layoutItem?.node?.widget?.widgetmediaSet.edges.filter(
              (widgetMedia) =>
                widgetMedia?.node?.mediaContent &&
                widgetMedia?.node.mediaContent.contentType !==
                  MediaMediaContentContentTypeChoices.If
            )
          )
        )
      )
      .flat(3)
      .map((media) => media?.node?.mediaContent as MediaContentFragment)
      .filter((media) => media !== null)
      .filter((media) => media !== undefined) || [];

  const playerAssignedVersion = displayProgram?.computer.playerVersion?.number;
  if (!!playerAssignedVersion) {
    handleVersion(playerAssignedVersion.toString());
  }

  const formatDay = (day: boolean | undefined) => day === undefined || day;
  const isDayActive = () =>
    formatDay(displayProgram?.computer[getDayOfTheWeek()]);

  const displayIsOn =
    useInTimeRange(
      displayProgram?.computer.startTime,
      displayProgram?.computer.endTime
    ) && isDayActive();
  const activeDays = DAYS.reduce(
    (days, day) => ({
      ...days,
      [day]: formatDay(displayProgram?.computer[day]),
    }),
    {} as ActiveDays
  );
  useUpdateSchedule(
    sendJsonMessage,
    readyState,
    displayProgram?.computer.startTime,
    displayProgram?.computer.endTime,
    activeDays
  );
  useEffect(() => {
    if (publications && publicationIndex > publications.length - 1) {
      setPublicationIndex(0);
    }
  }, [publicationIndex, publications?.length]);

  const nextPublication = useCallback(() => {
    const nextPublicationIndex =
      publicationIndex + 1 >= (publications?.length || 0)
        ? 0
        : publicationIndex + 1;
    PlatformLogging.getLogger().info({
      message: "Publication Rotation",
      nextPublicationId: publications?.[nextPublicationIndex]?.id,
    });
    setPublicationIndex(nextPublicationIndex);
  }, [publicationIndex, publications?.length]);

  if (displayIsOn && displayProgram) {
    if (publications && publications.length > 0) {
      const publicationScreens =
        publications[publicationIndex]?.publicationscreenSet.edges;
      if (publicationScreens && publicationScreens.length > 0) {
        const publicationScreen = publicationScreens[0];
        console.log("mediaList", mediaList);
        if (publicationScreen?.node) {
          return (
            <PersistedMedia mediaList={mediaList}>
              <Player
                key={publicationScreen.node.id}
                publicationScreen={publicationScreen.node}
                offsetX={displayProgram.offsetX}
                offsetY={displayProgram.offsetY}
                width={displayProgram.widthPx}
                height={displayProgram.heightPx}
                onComplete={nextPublication}
                isMuted={displayProgram.computer.isMuted}
              />
            </PersistedMedia>
          );
        }
      }
    }
  }

  return (
    <Box
      left={0}
      top={0}
      height="100vh"
      width="100vw"
      position="absolute"
      bg="black"
    />
  );
};
