import { FC, useEffect } from "react";
import { MediaProps } from ".";
import { parseMediaMetaData } from "../metaData";

export const IframeMedia: FC<MediaProps> = ({ widgetMedia, nextMedia }) => {
  const mediaContent = widgetMedia.mediaContent;
  const mediaMetaData = parseMediaMetaData(widgetMedia.data);
  useEffect(() => {
    const timeout = setTimeout(nextMedia, mediaMetaData.duration * 1000);
    return () => clearTimeout(timeout);
  }, [widgetMedia, nextMedia, mediaMetaData.duration]);
  return (
    <iframe
      height="100%"
      width="100%"
      src={mediaContent.webpageUrl || ""}
      frameBorder="0"
      allow="encrypted-media"
    />
  );
};
