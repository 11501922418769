export type WidgetMetaDataType = {};
export type MediaMetaDataType = {
  order: number;
  duration: number;
  object_fit: string;
  start_date: Date | null;
  end_date: Date | null;
};

export function parseWidgetMetaData(metaDataJson: string): WidgetMetaDataType {
  return JSON.parse(metaDataJson);
}
function parseDate(date: string | null): Date | null {
  if (date === "None") {
    return null;
  } else {
    return date ? new Date(date) : null;
  }
}
export function parseMediaMetaData(metaDataJson: string): MediaMetaDataType {
  const data = JSON.parse(metaDataJson);
  data.start_date = parseDate(data.start_date);
  data.end_date = parseDate(data.end_date);
  return data;
}

export function dumpMediaMetaData(metaData: MediaMetaDataType): string {
  return JSON.stringify(metaData);
}

export function toISOString(date: Date | null) {
  return date ? date.toISOString().split("T")[0] : null;
}

export function toDate(date: string | null) {
  return date && date !== "" ? new Date(date) : null;
}
