import { Box } from "@chakra-ui/layout";
import { FunctionComponent, memo, useCallback } from "react";
import { LayoutItemFragment } from "../generated/graphql";
import { PlaylistWidget } from "../widgets/Playlist";
import { WeatherWidget } from "@govisupro/weather-widget";
import { ScrollWidget } from "@govisupro/scroll-widget";
import { TextWidget } from "@govisupro/text-widget";
import { PdfWidget } from "@govisupro/pdf-widget";
import { YoutubeWidget } from "@govisupro/youtube-widget";
import { ClockWidget } from "@govisupro/clock-widget";
import { LayoutItemType, WidgetType } from "@govisupro/studio";

const Widgets = [
  PlaylistWidget,
  ScrollWidget,
  WeatherWidget,
  TextWidget,
  PdfWidget,
  YoutubeWidget,
  ClockWidget,
];

type PlayerLayoutZoneProps = {
  layoutItem: LayoutItemFragment;
  onComplete?: (layoutItemId: string) => void;
  screenWidth: number;
};

export const PlayerLayoutZone: FunctionComponent<PlayerLayoutZoneProps> = memo(
  ({ layoutItem, onComplete, screenWidth }) => {
    const onWidgetComplete = useCallback(() => {
      onComplete && onComplete(layoutItem.id);
    }, [onComplete, layoutItem.id]);

    const widget: WidgetType | undefined = Widgets.find(
      (widget) => widget.name === layoutItem.widget?.widgetType.name
    ) as WidgetType;
    const WidgetContext = widget?.contextDevice;
    const WidgetContent = widget?.playerDevice;
    const studioLayoutItem = LayoutItemNodeToType(layoutItem);

    if (!WidgetContext || !WidgetContent) {
      return null;
    }

    return (
      <WidgetContext layoutItem={layoutItem} onComplete={onWidgetComplete}>
        <Box
          key={"media-container-" + layoutItem?.id || ""}
          position="absolute"
          width={layoutItem.width}
          height={layoutItem.height}
          left={layoutItem.x}
          top={layoutItem.y}
          zIndex={layoutItem.z}
          cursor="none"
          transform={`scale(${
            widget.scalePlayer === false ? 1 : layoutItem.scale ?? 1
          })`}
          transformOrigin="top left"
        >
          <WidgetContent
            key={`player-layout-item-${layoutItem.width}-${layoutItem.scale}`}
            layoutItem={studioLayoutItem}
            screenWidth={screenWidth}
          />
        </Box>
      </WidgetContext>
    );
  }
);

function LayoutItemNodeToType(node: LayoutItemFragment): LayoutItemType {
  return {
    id: node.id,
    name: node.name,
    x: node.x,
    y: node.y,
    z: parseInt(node.z),
    width: node.width,
    height: node.height,
    unit: node.width.endsWith("%") ? "%" : "px",
    widgetName: node.widget?.widgetType.name || "",
    scale: node.scale ?? undefined,
    animations: JSON.parse(node.animations),
    widget: {
      id: "widget" + node.id,
      data:
        node.widget && node.widget?.data !== ""
          ? JSON.parse(node.widget?.data)
          : {},
    },
  };
}
