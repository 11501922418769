import { useEffect } from "react";
import { ReadyState } from "react-use-websocket";
import { SendJsonMessage } from "react-use-websocket/dist/lib/types";
import { setInterval } from "timers";
import pino from "pino";
import { getDisplayToken, storeDisplayToken } from "./auth";
import { ActiveDays } from "./schedule";

export const useHeartBeat = (
  sendJsonMessage: SendJsonMessage,
  readyState: ReadyState
) => {
  useEffect(() => {
    const loop = setInterval(
      () =>
        readyState === ReadyState.OPEN &&
        sendJsonMessage({
          type: "INFO",
          data: {
            type: "HEARTBEAT",
          },
        }),
      1000
    );
    return () => {
      clearInterval(loop);
    };
  }, [sendJsonMessage, readyState]);
};

export type DisplayMode = "ON" | "OFF";

export const toggleDisplay = (
  sendJsonMessage: SendJsonMessage,
  mode: DisplayMode
) => {
  sendJsonMessage({
    type: "COMMAND",
    data: {
      type: `TURN_${mode}_SCREEN`,
    },
  });
};

type ObjectSignatureFunction = (obj: Object) => string;
const objectSignature: ObjectSignatureFunction = (obj: Object) => {
  return Object.values(obj)
    .map((value) =>
      typeof value === "object" ? objectSignature(value) : value
    )
    .join("");
};

export const useUpdateSchedule = (
  sendJsonMessage: SendJsonMessage,
  readyState: ReadyState,
  startTime: string,
  endTime: string,
  activeDays: ActiveDays
) => {
  const scheduleSignature = objectSignature({ startTime, endTime, activeDays });
  useEffect(() => {
    readyState === ReadyState.OPEN &&
      startTime !== null &&
      endTime !== null &&
      sendJsonMessage({
        type: "INFO",
        data: {
          type: "SCHEDULE",
          data: {
            startTime: startTime,
            endTime: endTime,
            activeDays: activeDays,
          },
        },
      });
  }, [
    sendJsonMessage,
    readyState,
    scheduleSignature,
    activeDays,
    endTime,
    startTime,
  ]);
};

export const handlePlatformMessage = async (message: MessageEvent) => {
  const data = JSON.parse(message.data);
  if (data.type === "updateDisplayToken") {
    if (data.value !== null) {
      PlatformLogging.getLogger().info({
        message: "Update DisplayToken from platform",
      });
      await storeDisplayToken(data.value);
    }
  }
};

export class PlatformAPI {
  private static websocketSend: SendJsonMessage;
  private static readyState: ReadyState;

  public static init(sendJsonMessage: SendJsonMessage, readyState: ReadyState) {
    PlatformAPI.websocketSend = sendJsonMessage;
    PlatformAPI.readyState = readyState;
  }

  public static sendJsonMessage(jsonMessage: any) {
    console.log(PlatformAPI.readyState);
    if (PlatformAPI.websocketSend) {
      PlatformAPI.websocketSend(jsonMessage);
    }
  }
}

export class PlatformLogging {
  private static logger: pino.Logger;

  public static init(sendJsonMessage: SendJsonMessage, readyState: ReadyState) {
    PlatformLogging.logger = pino({
      browser: {
        transmit: {
          send: async function (level, logEvent) {
            const displayToken = await getDisplayToken();
            if (readyState === ReadyState.OPEN) {
              sendJsonMessage({
                type: "LOG",
                data: {
                  level,
                  extra: {
                    displayToken,
                  },
                  message: JSON.stringify(logEvent.messages[0]),
                },
              });
            }
          },
        },
      },
    });
  }

  public static getLogger(): Console | pino.Logger {
    if (!PlatformLogging.logger) {
      console.error("error: logger not initialized");
      return console;
    }
    return PlatformLogging.logger;
  }
}
