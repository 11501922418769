import "./App.css";
import { BrowserRouter as Router, Redirect, Route } from "react-router-dom";
import { FunctionComponent, useEffect, useState } from "react";
import { client, restoreCache } from "./api";
import { Register } from "./screens/Register";

import {
  Center,
  ChakraProvider,
  HStack,
  Spinner,
  Text,
} from "@chakra-ui/react";
import theme from "./theme";
import { ApolloProvider } from "@apollo/client";
import { PublicationReader } from "./screens/PublicationReader";
import { useIsRegistered } from "./libs/auth";
import { SOFTWARE_VERSION } from "./version";

const PrivateRoute = ({
  component: ChildComponent,
  ...rest
}: {
  component: FunctionComponent<any>;
  path?: any;
  exact?: boolean;
}) => {
  const { loading, registered } = useIsRegistered();
  return loading ? (
    <HStack>
      <Text>V{SOFTWARE_VERSION}</Text>
      <Spinner />
    </HStack>
  ) : (
    <Route
      {...rest}
      render={(...props) =>
        registered ? <ChildComponent /> : <Redirect to="/register" />
      }
    />
  );
};

function App() {
  const [cacheLoaded, setCacheLoaded] = useState(false);
  useEffect(() => {
    restoreCache().then(() => {
      setCacheLoaded(true);
    });
  }, []);

  return (
    <ApolloProvider client={client}>
      <ChakraProvider theme={theme}>
        {cacheLoaded ? (
          <Router>
            <Route path="/register" exact component={Register} />
            <PrivateRoute path="/" component={PublicationReader} />
          </Router>
        ) : (
          <Center height="100vh" width="100vw" justifySelf="center">
            <Spinner size="xl" />
          </Center>
        )}
      </ChakraProvider>
    </ApolloProvider>
  );
}

export default App;
