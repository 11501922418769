import { memo, useContext } from "react";
import { PersistedMediaContext } from "../../../components/PersistedMedia";
import {
  MediaMediaContentContentTypeChoices,
  WidgetMediaFragment,
} from "../../../generated/graphql";
import { PersitedMedia } from "../../../libs/persistMedia";
import { PlaylistContext } from "../contextDevice";
import { IframeMedia } from "./Iframe";
import { ImageMedia } from "./Image";
import { VideoMedia } from "./Video";

export type MediaProps = {
  widgetMedia: WidgetMediaFragment;
  offlineMedia: PersitedMedia;
  nextMedia: () => void;
};

export const MediaPlayer = memo(() => {
  const { currentMedia, nextMedia } = useContext(PlaylistContext);
  const { persistedMedia } = useContext(PersistedMediaContext);

  const offlineMedia = persistedMedia[currentMedia?.mediaContent?.id || ""];

  const Media = currentMedia
    ? WidgetMedia[currentMedia.mediaContent.contentType]
    : null;

  return Media && currentMedia ? (
    <Media
      widgetMedia={currentMedia}
      offlineMedia={offlineMedia}
      nextMedia={nextMedia}
    />
  ) : (
    <div></div>
  );
});

const WidgetMedia = {
  [MediaMediaContentContentTypeChoices.Im]: ImageMedia,
  [MediaMediaContentContentTypeChoices.Vd]: VideoMedia,
  [MediaMediaContentContentTypeChoices.If]: IframeMedia,
};
