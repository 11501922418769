import { useEffect, useState } from "react";
import localforage from "localforage";
import { PlatformAPI } from "./platformAPI";

export const AUTH_TOKEN = "DQS76D76SQ86D";

export const getDisplayToken = async () => {
  let token = localStorage.getItem(AUTH_TOKEN);
  if (token === null) {
    token = await localforage.getItem(AUTH_TOKEN);
    if (token === null) {
      PlatformAPI.sendJsonMessage({
        type: "INFO",
        data: {
          type: "GET_DISPLAY_TOKEN",
        },
      });
    } else {
      localStorage.setItem(AUTH_TOKEN, token);
    }
  } else {
    await localforage.setItem(AUTH_TOKEN, token);
  }
  return token;
};

export const storeDisplayToken = async (token: string) => {
  localStorage.setItem(AUTH_TOKEN, token);
  await localforage.setItem(AUTH_TOKEN, token);
};

export const isRegistered = async () => {
  const token = await getDisplayToken();
  return token !== null;
};

export const useIsRegistered = () => {
  const [registered, setIsRegistered] = useState(false);
  const [loading, setIsLoading] = useState(true);
  useEffect(() => {
    setIsLoading(true);
    const interval = setInterval(async () => {
      isRegistered()
        .then((result) => {
          setIsRegistered(result);
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          console.error(error);
        });
    }, 5000);
    return () => clearInterval(interval);
  }, []);
  return { registered, loading };
};
