import { InMemoryCache, ApolloClient, createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { CachePersistor } from "apollo-cache-persist";
import { PersistedData, PersistentStorage } from "apollo-cache-persist/types";
import { getDisplayToken } from "../libs/auth";

const httpLink = createHttpLink({
  uri: "/graphql/",
  credentials: "same-origin",
});

const authLink = setContext(async (_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = await getDisplayToken();
  // return the headers to the context so httpLink can read them
  return token
    ? {
        headers: {
          ...headers,
          DisplayToken: token,
        },
      }
    : {};
});

const cache = new InMemoryCache({});

export const client = new ApolloClient({
  uri: "/graphql/",
  link: authLink.concat(httpLink),
  cache,
});

const SCHEMA_VERSION = "1";
const SCHEMA_VERSION_KEY = "apollo-schema-version";

const persistor = new CachePersistor({
  cache,
  storage: window.localStorage as PersistentStorage<PersistedData<string>>,
});

export const restoreCache = async () => {
  const currentVersion = window.localStorage.getItem(SCHEMA_VERSION_KEY);

  if (currentVersion === SCHEMA_VERSION) {
    await persistor.restore();
  } else {
    await persistor.purge();
    window.localStorage.setItem(SCHEMA_VERSION_KEY, SCHEMA_VERSION);
  }
};
