import { Box } from "@chakra-ui/layout";
import { AspectRatio } from "@chakra-ui/react";
import { FontInfo, Loader } from "@govisupro/studio/dist/studio/types";
import {
  FunctionComponent,
  useState,
  useContext,
  useCallback,
  useEffect,
} from "react";
import WebFont from "webfontloader";
import { PublicationScreenFragment } from "../generated/graphql";
import { PlatformLogging } from "../libs/platformAPI";
import { PlayerContext } from "../libs/playerContext";
import { PersistedMediaContext } from "./PersistedMedia";
import { PlayerLayoutZone } from "./PlayerLayoutZone";

type PlayerProps = {
  width: number;
  height: number;
  offsetX: number;
  offsetY: number;
  publicationScreen: PublicationScreenFragment;
  isMuted: boolean;
  onComplete?: () => void;
};

type LayoutItemsCompletionType = {
  [key: string]: Boolean;
};

export const Player: FunctionComponent<PlayerProps> = ({
  offsetX,
  offsetY,
  width,
  height,
  publicationScreen,
  isMuted,
  onComplete,
}) => {
  const layoutItems =
    publicationScreen?.layoutitemSet.edges
      .map((layoutItem) => layoutItem?.node)
      .filter((layoutItem) => layoutItem !== null) || [];

  const [layoutItemsCompletion, setLayoutItemsCompletion] =
    useState<LayoutItemsCompletionType>(
      layoutItems
        .filter(
          (layoutItem) => layoutItem?.widget?.widgetType.name === "Playlist"
        )
        .reduce(
          (prev, layoutItem) => ({ ...prev, [layoutItem!.id]: false }),
          {}
        )
    );
  const { persistedMedia } = useContext(PersistedMediaContext);
  const [isLoadingFonts, setLoadingFonts] = useState(false);
  PlatformLogging.getLogger().info({
    persistedMedia,
  });
  const layoutItemsCompletionSignature = JSON.stringify(layoutItemsCompletion);

  const completeLayoutItem = useCallback(
    (layoutId: string) => {
      let nextVal = { ...layoutItemsCompletion };
      console.log("layoutItemsCompletion", layoutItemsCompletion);
      nextVal[layoutId] = true;
      console.log("nextVal", nextVal);
      // if all value is true, means that all layout has rotated, so propagate the info higher

      setLayoutItemsCompletion(nextVal);
      if (Object.values(nextVal).reduce((prev, val) => prev && val, true)) {
        onComplete && onComplete();
        console.log("Layout Completion");
        PlatformLogging.getLogger().info({
          message: "Layout Completion",
          LayoutId: layoutId,
        });
      }
    },
    [layoutItemsCompletionSignature, onComplete]
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (Object.keys(layoutItemsCompletion).length === 0) {
        onComplete && onComplete();
      }
    }, (publicationScreen.duration || 10) * 1000);
    return () => clearTimeout(timeout);
  }, [layoutItemsCompletionSignature, onComplete, publicationScreen.duration]);

  useEffect(() => {
    loadFonts().then(() => setLoadingFonts(false));
  }, []);

  return isLoadingFonts ? (
    <Box bg="black"></Box>
  ) : (
    <PlayerContext.Provider value={{ isMuted }}>
      <AspectRatio
        marginLeft={offsetX}
        marginTop={offsetY}
        width={`${width}px`}
        height={`${height}px`}
        ratio={width / height}
        bg={publicationScreen.backgroundColor}
      >
        <Box height={height}>
          {publicationScreen?.layoutitemSet.edges.map((layoutItem, index) => {
            return (
              layoutItem?.node && (
                <PlayerLayoutZone
                  key={`zone-${index}-${layoutItem.node.id}`}
                  layoutItem={layoutItem.node}
                  onComplete={completeLayoutItem}
                  screenWidth={width}
                />
              )
            );
          })}
        </Box>
      </AspectRatio>
    </PlayerContext.Provider>
  );
};

const loadFonts = () => {
  return new Promise((resolve, reject) => {
    WebFont.load({
      google: {
        families: fonts
          .filter((font) => font.provider === "google")
          .map((font) => font.name + ":" + font.weights.join(",")),
      },
      active: () => resolve(null),
      inactive: reject,
    });
  });
};

const fonts: FontInfo[] = [
  {
    name: "Inter",
    loader: Loader.webfontloader,
    provider: "google",
    weights: ["300", "400", "700"],
  },
  {
    name: "Poppins",
    loader: Loader.webfontloader,
    provider: "google",
    weights: ["300", "400", "700"],
  },
  {
    name: "Montserrat",
    loader: Loader.webfontloader,
    provider: "google",
    weights: ["300", "400", "700"],
  },
  {
    name: "Rubik",
    loader: Loader.webfontloader,
    provider: "google",
    weights: ["300", "400", "700"],
  },
  {
    name: "Mulish",
    loader: Loader.webfontloader,
    provider: "google",
    weights: ["300", "400", "700"],
  },
  {
    name: "Lato",
    loader: Loader.webfontloader,
    provider: "google",
    weights: ["300", "400", "700"],
  },
  {
    name: "Space Grotesk",
    loader: Loader.webfontloader,
    provider: "google",
    weights: ["300", "400", "700"],
  },
  {
    name: "Raleway",
    loader: Loader.webfontloader,
    provider: "google",
    weights: ["300", "400", "700"],
  },
  {
    name: "Open Sans",
    loader: Loader.webfontloader,
    provider: "google",
    weights: ["300", "400", "700"],
  },
  {
    name: "Alegreya",
    loader: Loader.webfontloader,
    provider: "google",
    weights: ["300", "400", "700"],
  },
  {
    name: "Oswald",
    loader: Loader.webfontloader,
    provider: "google",
    weights: ["300", "400", "700"],
  },
  {
    name: "Lobster",
    loader: Loader.webfontloader,
    provider: "google",
    weights: ["400", "700"],
  },
  {
    name: "Arial",
    loader: Loader.system,
    provider: "google",
    weights: ["300", "400", "700"],
  },
];

function shallowEqual(
  object1: LayoutItemsCompletionType,
  object2: LayoutItemsCompletionType
) {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);
  if (keys1.length !== keys2.length) {
    return false;
  }
  for (let key of keys1) {
    if (object1[key] !== object2[key]) {
      return false;
    }
  }
  return true;
}
